import styled from "styled-components";

const transition = 'height 0.15s 0s ease, width 0.15s 0s ease'

interface Props {
  seeking: boolean;
  highlighted: string;
  colorBefore: string;
  colorAfter: string;
  percent: number;
  growTo: number;
  size: number;
  width: number;
  height: number;
}
const MusicSlider = styled.input<Props>`
  cursor: pointer;
  background: linear-gradient(
    to right,
    ${(p: Props) => (p.seeking ? p.highlighted : p.colorBefore)} 0%,
    ${(p: Props) => (p.seeking ? p.highlighted : p.colorBefore)}
      ${(p: Props) => p.percent}%,
    ${(p: Props) => p.colorAfter} ${(p: Props) => p.percent}%,
    ${(p: Props) => p.colorAfter} 100%
  );
  border-radius: 8px;
  height: ${(p: Props) => (p.height)}px;
  width: ${(p: Props) => (p.width)}px;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-transition: ${transition};
  -moz-transition: ${transition};
  -o-transition: ${transition};
  transition: ${transition};
  -webkit-appearance: none;
  &::-webkit-slider-thumb {
    border: none;
    -webkit-appearance: none;
    width: ${(props: Props) => (props.seeking ? props.growTo : props.size)}px;
    height: ${(props: Props) => (props.seeking ? props.growTo : props.size)}px;
    cursor: pointer;
    background: ${(props: Props) => (props.seeking ? props.highlighted : props.colorBefore)};
    border-radius: 50%;
  }
  &::-ms-thumb {
    border: none;
    height: ${(props: Props) => (props.seeking ? props.growTo : props.size)}px;
    width: ${(props: Props) => (props.seeking ? props.growTo : props.size)}px;
    border-radius: 50%;
    background: ${(props: Props) => (props.seeking ? props.highlighted : props.colorBefore)};
    cursor: pointer;
  }
  &::-moz-range-thumb {
    border: none;
    height: ${(props: Props) => (props.seeking ? props.growTo : props.size)}px;
    width: ${(props: Props) => (props.seeking ? props.growTo : props.size)}px;
    border-radius: 50%;
    background: ${(props: Props) => (props.seeking ? props.highlighted : props.colorBefore)};
    cursor: pointer;
  }
`;
export default MusicSlider
