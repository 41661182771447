import React, {useEffect} from "react";
import {publicRequest, request} from "../services/api.service";
import {useQuery} from "react-query";
import {ContentBoxType, TimelineType} from "../typings/timeline";
import './Home.scss'
import ContentBox from "../components/pages/ContentBox";
import {UseAppDispatch} from "../redux/store";
import {useHistory} from "react-router-dom";
import {PLAYER_DATA, SHOW_MODAL_PREMIUM, SHOW_PLAYER} from "../redux/types";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {shuffle} from "../utils/common";
import useQueryParams from "../hooks/useQueryParams";

export const Home: React.FC = () => {
	const queryParams = useQueryParams();
	const dispatch = UseAppDispatch();
	const history = useHistory();
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const { data: timelineList, isLoading, refetch } = useQuery<TimelineType[],boolean>('home', async () => {
		try{
			const response = await request('GET',`/webplayer/v1/home${user.authenticated?'-member':''}/timeline`)
			return response?.data as TimelineType[]
		}catch (e) {
			return []
		}
	},{enabled: false})

	useEffect(() => {
		if(queryParams.get('red')){
			if(user?.authenticated){
				window.location.replace(queryParams.get('red') || '')
			}else{
				dispatch({
					type: SHOW_MODAL_PREMIUM,
					payload: {
						thumbnail: null
					}
				})
			}
		}
		// console.log(timelineList)
	},[queryParams.get('red'),user])
	useEffect(() => {
		if(user.authenticated || user.authenticated === false){
			refetch()
		}
	},[user.authenticated])


	const playMusic = (data: {
		artist?: string
		song: string
		thumbnail: string
		url: string
	}) => {
		const playlistOrigin = [{
			artist: data?.artist,
			song: data.song,
			thumbnail: data.thumbnail,
			music_url: data.url,
		}]
		const playlistShuffle = [...playlistOrigin];
		dispatch({
			type: PLAYER_DATA,
			payload: {
				music_data: data,
				playlistOrigin,
				playlistShuffle
			}
		})
		dispatch({ type: SHOW_PLAYER })
		sendMusicPlay(data.url)
	}

	const getMapContentBox = (list: any[], category:string): ContentBoxType[] => {
		switch (category) {
			case 'For You':
				return list.map(l => {
					return {
						title: l.title,
						covers: l.covers,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/personalized-playlist/${l.slug}`)
							}
						}
					}
				})
			case 'Recommended Playlist':
				return list.map(l => {
					return {
						title: l.title,
						thumbnail: l.thumbnail,
						subtitle: 'playlist',
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/playlist/${l.slug}`)
							}
						}
					}
				})
			case 'Recently Played':
				return list.map(l => {
					let content: any = {
						thumbnail: l.type === 'playlist'? l.cover: l.thumbnail,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'playlist'){
									history.push(`/playlist/${l.slug}`)
								}else if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}else if(l.type === 'song'){
									playMusic({
										song: l.title,
										thumbnail: l.thumbnail,
										url: l.slug,
									})
								}
							}
						}
					}
					switch(l.type){
						case 'playlist':
							content['title'] = l.title
							content['subtitle'] = 'playlist'
							break;
						case 'song':
							content['title'] = l.title
							content['subtitle'] = l.caption
							break;
						case 'album':
							content['title'] = l.name
							content['subtitle'] = l.caption
							break;
					}
					return content
				})
			case 'New releases':
				return list.map(l => {
					return {
						title: l.artist,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'album'){
									history.push(`/album/${l.id}`)
								}else if(l.type === 'song'){
									playMusic({
										artist: l.artist,
										song: l.name,
										thumbnail: l.thumbnail,
										url: l.id,
									})
								}
							}
						}
					}
				})
			case 'Artists Store':
				return list.map(l => {
					return {
						title: l.name,
						thumbnail: l.thumbnail,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/artist/${l.id}`)
							}
						}
					}
				})
			case 'Popular Artists':
				return list.map(l => {
					return {
						title: l.name,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/artist/${l.id}`)
							}
						}
					}
				})
			case 'Popular Albums':
				return list.map(l => {
					return {
						title: l.name,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}
							}
						}
					}
				})
			case 'Trending':
				return list.map(l => {
					return {
						title: l.title,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}else if(l.type === 'song'){
									playMusic({
										artist: l.artist,
										song: l.title,
										thumbnail: l.thumbnail,
										url: l.slug,
									})
								}
							}
						}
					}
				})
			default:
				return list.map(l => {
					let content: any = {
						thumbnail: l.thumbnail,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'playlist'){
									history.push(`/playlist/${l.slug}`)
								}else if(l.type === 'artist'){
									history.push(`/artist/${l.slug}`)
								}else if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}else if(l.type === 'song'){
									playMusic({
										artist: l.artist,
										song: l.title,
										thumbnail: l.thumbnail,
										url: l.slug,
									})
								}
							}
						}
					}
					switch(l.type){
						case 'playlist':
							content['title'] = l.title
							content['subtitle'] = 'playlist'
							break;
						case 'song':
							content['title'] = l.title
							content['subtitle'] = l.caption
							break;
						case 'album':
							content['title'] = l.name
							content['subtitle'] = l.caption
							break;
						case 'artist':
							content['title'] = l.name
							content['subtitle'] = l.caption
							break;
					}
					return content
				})
		}
		return []
	}
	const goMore = (category: string) => {
		history.push(`/timeline/${encodeURI(category)}`)
	}
	const sendMusicPlay = async (music_slug: string) => {
		await request('POST',`/webplayer/v1/music/play`,{
			"slug" : music_slug,
			"source": 1,
			"is_offline": true,
		})
	}
	return (
		<>
			<div className={'main-container'}>
				<h1 className={'page-title'}>Home</h1>
				<div className='timeline-list d-flex flex-column'>
					{
						timelineList ? (
							<>
								{
									timelineList.map((t,idx) => (
										<div className={'tl-items'} key={`tl-${idx}`}>
											<div className={'tl-items-header'}>
												<div className={'tl-title'}>{t.category}</div>
												{t.more && <a className={'tl-more'} onClick={()=>goMore(t.category)}>More</a>}
											</div>
											<div className={'tl-content'}>
												<>
													{getMapContentBox(t.list, t.category).map((l: any,idx2) => (
														<ContentBox
															thumbnail={l.thumbnail ?? undefined}
															covers={l.covers ?? undefined}
															title={l.title}
															subtitle={l.subtitle?l.subtitle:undefined}
															onClick={l.onClick?l.onClick:undefined}
															playOverlay={true} key={`cb-${idx}-${idx2}`} />
													))}
												</>
											</div>
										</div>
									))
								}
							</>
						): null
					}
				</div>
			</div>
		</>
	)
}
