import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {UseAppDispatch} from "../redux/store";
import {ContentBoxType, ModalPremiumType} from "../typings/timeline";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {PLAYER_DATA, SHOW_MODAL_LOGIN, SHOW_MODAL_PREMIUM, SHOW_PLAYER} from "../redux/types";
import {SearchItem} from "../typings/search";
import {useInfiniteQuery, useQuery} from "react-query";
import {request} from "../services/api.service";
import './Home.scss'
import ContentBox from "../components/pages/ContentBox";
import { useInView } from 'react-intersection-observer'
import {ApiDataResponse} from "../typings/api";
import useQueryParams from "../hooks/useQueryParams";

const SearchType: React.FC<RouteComponentProps> = (props) => {
	const { ref, inView } = useInView()
	const dispatch = UseAppDispatch();
	const queryParams = useQueryParams();
	const history = useHistory();
	const params:any  = useParams();
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const [querySearch, setQuerySearch] = useState<string | null>(null)
	const [type, setType] = useState<'artist'|'album'|'song'|null>(null);
	const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery<ApiDataResponse>(['search-by',type, querySearch], async ({ pageParam = 1 }) => {
		if(type && querySearch){
			try{
				const response = await request('GET',`/webplayer/v1/search${user.authenticated?'-member':''}/${type}`,{
					page: pageParam,
					q: querySearch,
				})
				return response as ApiDataResponse
			}catch (e) {
				return [] as ApiDataResponse
			}
		}else{
			return [] as ApiDataResponse
		}
	},{
		getPreviousPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		getNextPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		enabled: false
	})

	useEffect(() => {
		if(params?.type){
			setType(params?.type)
			setQuerySearch(queryParams.get('q'))
		}
	},[])

	useEffect(() => {
		if(!!type && !['song','artist','album'].includes(type)){
			history.push('/')
		}
	},[type])

	useEffect(() => {
		if((user.authenticated || user.authenticated === false) && querySearch && type){
			fetchNextPage();
		}
	},[user.authenticated,querySearch, type])
	useEffect(() => {
		if(inView){
			fetchNextPage();
		}
	},[inView])

	useEffect(() => {
		if(data){
			console.log(data)
		}
	},[data])

	const onEnter = (value: string) => {
		setQuerySearch(value !=='' ? value : null)
	}
	const playMusic = (data: {
		artist?: string
		song: string
		thumbnail: string
		url: string
	}) => {
		const playlistOrigin = [{
			artist: data?.artist,
			song: data.song,
			thumbnail: data.thumbnail,
			music_url: data.url,
		}]
		const playlistShuffle = [...playlistOrigin];
		dispatch({
			type: PLAYER_DATA,
			payload: {
				music_data: data,
				playlistOrigin,
				playlistShuffle
			}
		})
		dispatch({ type: SHOW_PLAYER })
		sendMusicPlay(data.url)
	}
	const sendMusicPlay = async (music_slug: string) => {
		await request('POST',`/webplayer/v1/music/play`,{
			"slug" : music_slug,
			"source": 1,
			"is_offline": true,
		})
	}

	const getMapContentBox = (list: SearchItem[]): ContentBoxType[] => {
		return list.map(l => {
			return {
				title: l.name,
				thumbnail: l.picture,
				subtitle: l.type !== 'artist'?l.caption:undefined,
				circle: l.type === 'artist',
				playOverlay: l.type !== 'artist',
				onClick: () => {
					if(!user.authenticated){
						dispatch({
							type: SHOW_MODAL_PREMIUM,
							payload: {
								thumbnail: l.picture
							}
						})
					}else{
						if(l.type === 'playlist'){
							history.push(`/playlist/${l.slug}`)
						}else if(l.type === 'artist'){
							history.push(`/artist/${l.slug}`)
						}else if(l.type === 'album'){
							history.push(`/album/${l.slug}`)
						}else if(l.type === 'song'){
							playMusic({
								artist: l.artist,
								song: l.name,
								thumbnail: l.picture,
								url: l.slug,
							})
						}
					}
				}
			}
		})
	}

	const EmptySearch = () => {
		return <>
			<div className={'empty-search'}>
				<div className={'es-content'}>
					<h3>No Result Found</h3>
					<p>Try using another keyword</p>
				</div>
			</div>
		</>
	}

	return <>
		<div className={'main-container'}>
			<h1 className={'page-title text-capitalize'}>Search {type ? `${type}s`:''}</h1>
			<div className={'search-form'}>
				<input type={'text'} className={'search-input'} defaultValue={querySearch || ''}
				       onKeyDown={event => {
					       if (event.key === 'Enter' || event.keyCode === 13) {
						       onEnter(event.currentTarget.value);
					       }
				       }}
				/>
				<i className={'fa fa-search'}></i>
			</div>
			<div className={'content-search'}>
				{ data?.pages && (
						<>
							{data.pages[0]?.data?.length > 0 && (
								<div className='timeline-list d-flex flex-column single'>
									<div className={'tl-items'}>
										<div className={'tl-content loskeun'}>
											{data?.pages.map((l: any,idx1) => (
												<React.Fragment key={`st-more-pages-${idx1}`}>
													{getMapContentBox(l.data).map((l: any,idx2) => (
														<ContentBox
															thumbnail={l.thumbnail ?? undefined}
															title={l.title}
															circle={l.circle}
															subtitle={l.subtitle?l.subtitle:undefined}
															onClick={l.onClick?l.onClick:undefined}
															playOverlay={l.playOverlay} key={`cb-search-cat-${idx2}`} />
													))}
												</React.Fragment>
											))}
										</div>
									</div>
									<span ref={ref} className={'edge-infinite'}></span>
								</div>
							)}
							{data.pages[0]?.data?.length === 0 && <EmptySearch />}
						</>
					)
				}

			</div>
		</div>
	</>
}
export default SearchType
