import { UserType } from '../typings/user'
import {storageKeyToken, storageKeyUser} from "./constant";

export const isAuthenticated = (): boolean => {
  const _kmt_t = localStorage.getItem(storageKeyToken);
  return !!_kmt_t;
}

export const getToken = (): string | null => {
  return localStorage.getItem(storageKeyToken);
}

export const getUser = (): UserType | undefined => {
  const _kmt_t = localStorage.getItem(storageKeyUser);
  let user;
  if(_kmt_t){
    user = JSON.parse(_kmt_t) as UserType;
  }
  return user;
}

const isValidEmail = (email:any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
const isStrongPassword = (password:any) =>
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#\-_?&])[A-Za-z\d@$!%*#\-_?&]{8,50}$/.test(
    password
  );

export const EmailValidation = (email:any) =>{
  return isValidEmail(email) || "email is not in valid format";
};
export const PasswordValidation = (password:any) =>{
  return isStrongPassword(password) || "minimum length 8, must contain at least one uppercase, one lowercase and special character";
};

export const formatNumber = (val: number) => {
  return val.toLocaleString('ID-id')
}

export function secondToMinutes(second: number) {
  const minutes = Math.round(second / 60);
  return minutes
}

export function secondsToMinutesAndSeconds(second: number) {
  var minutes = Math.floor(second / 60);
  var seconds = ((second % 60)).toFixed(0);
  return minutes + ":" + (Number(seconds) < 10 ? '0' : '') + seconds;
}

export const shuffle = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];

    // Swap
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export function copyValue(val: string){
  const el = document.createElement('input');
  el.value = val;
  document.body.appendChild(el);
  try {
    if(navigator.userAgent.match(/ipad|ipod|iphone/i)){
      const range = document.createRange();
      el.contentEditable = "true";
      el.readOnly = false;
      range.selectNodeContents(el);

      const s = window.getSelection();
      // @ts-ignore
      s.removeAllRanges();
      // @ts-ignore
      s.addRange(range);
      el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
      document.execCommand('copy');
    }else{
      el.select();
      document.execCommand('copy');
    }
  }
  catch (err) {
    console.log(err,'please press Ctrl/Cmd+C to copy');
  }
  document.body.removeChild(el);
}
