import * as React from "react";
import {
  UseFormRegister,
  FieldValues,
  FieldValue,
  DeepMap,
  FieldError, RegisterOptions,
} from 'react-hook-form'

interface IInput {
  placeholder: string;
  type?: string;
  name?: FieldValue<any>;
  className?: string;
  register?: UseFormRegister<FieldValues>;
  errors?: DeepMap<FieldValues, FieldError>;
  defaultValue?: any;
  required?: boolean;
  block?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  rules?:RegisterOptions;
  Icon?:string;
}

const Input: React.FC<IInput> = ({
  placeholder,
  type = "text",
  className = "",
  name,
  register,
  errors,
  defaultValue,
  required,
  block,
  disabled,
  readonly,
  rules,
  Icon,
}: IInput) => {
  let styleInput = `form-control ${className}`;
  let styleWrapper = `input-wrapper`;
  if (block) {
    styleInput = `form-control w-full ${className}`;
  }
  if(Icon){
    styleWrapper+= ' input-group-icon';
  }
  const registerOptions = rules ?? {}
  const InputPlain = () => {
    return (
      <div className={styleWrapper}>
        {Icon && <i className={`${Icon} icon`}></i>}
        <input
          required={required}
          className={styleInput}
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={disabled}
          readOnly={readonly}
          defaultValue={defaultValue}
        />
      </div>
    )
  }

  return register ? (
    <div className={styleWrapper}>
      <input
        required={required}
        className={styleInput}
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        disabled={disabled}
        readOnly={readonly}
        {...register(name,registerOptions)}
      />
      {Icon && <i className={`${Icon} icon`}></i>}
      {errors?.[name] && (
        <p className="text-danger fst-italic mb-2">
          {errors[name].message}
        </p>
      )}
    </div>
  ) : <InputPlain />;
};
export default Input;
