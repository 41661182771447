import React from "react";
import './ContentBox.scss'
import playIcon from '../../assets/images/icon/play-icon.png'
import placeholder from "../../assets/images/playlist-placeholder.svg";
interface IContentBox{
	thumbnail?: string
	covers?: string[]
	title: string
	subtitle?: string
	onClick?: Function
	playOverlay?: boolean
	circle?: boolean
}
const ContentBox: React.FC<IContentBox> = (props) => {
	let covers: any[] = []
	if(props.covers){
		covers = props.covers;
		const coversLength = props.covers.length;
		const emptyLength = 4 - coversLength;
		if(emptyLength > 0){
			for(let it = 0;it<emptyLength;it++){
				covers.push(placeholder)
			}
		}
	}
	return <div className={`content-box`}>
		<div className={`cb-img ${props.playOverlay ? 'play-overlay':''} ${props.circle ? 'circle': ''}`} onClick={()=>{
			if(props.onClick){
				props.onClick()
			}
		}}>
			{ props.thumbnail && <img src={props.thumbnail} className={`img-single`}/> }
			{ props.covers ? (<div className={'img-multi'}>
				{ covers.map((i, it) => (<img src={i} key={`multi-${it}`}/>)) }
			</div>) : null }
			{props.playOverlay && <img className={'play-icon'} src={playIcon}></img>}
		</div>
		<div className={'cb-title'} onClick={()=>{
			if(props.onClick){
				props.onClick()
			}
		}}>{props.title}</div>
		{props.subtitle && <div className={'cb-subtitle'}>{props.subtitle}</div>}
	</div>
}

export default ContentBox
