import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import './Library.scss'
import {useQuery} from "react-query";
import {SearchItem} from "../typings/search";
import {request} from "../services/api.service";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {ContentBoxType} from "../typings/timeline";
import ContentBox from "../components/pages/ContentBox";
import {Modal} from "react-bootstrap";
import Button from "../components/layout/UI/Button";
import {useForm} from "react-hook-form";

const Library: React.FC<RouteComponentProps> = (props) =>{
	const { register, handleSubmit, formState: { errors }, getValues, watch } = useForm();
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const { data: searchData, refetch: refetchSearch } = useQuery<SearchItem[]>('library-search', async () => {
		try{
			const response = await request('GET',`/webplayer/v1/lib/main`,{
				type: tabActive,
				q: querySearch,
				page: 1,
			})
			return response?.data as SearchItem[]
		}catch (e) {
			return []
		}
	},{enabled: false})

	const [tabActive, setTabActive] = useState<number>(0);
	const [showModalPlaylist, setShowModalPlaylist] = useState<boolean>(false);
	const [querySearch, setQuerySearch] = useState<string>('')

	const handleClosePlaylist = () => setShowModalPlaylist(false);
	const handleShowPlaylist = () => setShowModalPlaylist(true);

	useEffect(()=>{
		if(user){
			refetchSearch()
		}
	},[user, tabActive])
	useEffect(() => {
		if(searchData){
			console.log('search',searchData);
		}
	},[searchData])
	const onSetTabActive = (tabs: number) => {
		setTabActive(tabs);
	}
	const isTabActive = (tabs: number) => {
		return tabActive === tabs ? 'active':'';
	}
	const handleQuerySearch = (value: string) => {
		setQuerySearch(value);
	}
	const enterSearch = () => {
		refetchSearch()
	}

	const getMapContentBox = (list: SearchItem[]): ContentBoxType[] => {
		return list.map(l => {
			const data: any = {
				title: l.name,
				circle: l.type === 'artist',
				onClick: () => {
					if(l.type === 'playlist'){
						props.history.push(`/playlist/${l.slug}`)
					}else if(l.type === 'artist'){
						props.history.push(`/artist/${l.slug}`)
					}else if(l.type === 'album'){
						props.history.push(`/album/${l.slug}`)
					}else if(l.type === 'purchased_contents'){
						props.history.push(`/purchased-content`)
					}
				}
			}
			switch (l.type){
				case 'playlist':
					data['subtitle'] = l?.song_count
					// @ts-ignore
					data['covers'] = l.thumbnail
					break;
				case 'purchased_contents':
					data['thumbnail'] = l.picture
					break;
				case 'artist':
				case 'album':
					data['thumbnail'] = l.picture;
					data['subtitle'] = l.type === 'artist' ? 'Artist' : l.caption
					break;
			}
			return data
		})
	}

	const EmptySearch = () => {
		return <>
			<div className={'empty-search'}>
				<div className={'es-content'}>
					<h3>No Result Found</h3>
					<p>Try using another keyword</p>
				</div>
			</div>
		</>
	}

	const onSubmitPlaylist = async (data: any) => {
		const response = await request('POST',`/webplayer/v1/lib/playlist/create`,{
			title: data.playlist_name,
		})
		handleClosePlaylist();
		props.history.push(`/playlist/${response?.data?.slug}`)
		// onSetTabActive(4)
		// console.log(response);
	}

	const CreatePlaylistAct = () => {
		return <div className={'content-box create-playlist'} onClick={()=>{handleShowPlaylist()}}>
			<div className={'cb-plus'}>
				<i className={'fa fa-plus'}></i>
			</div>
			<div className={'cb-title'}>Create Playlist</div>
		</div>
	}

	return <>
		<div className={'main-container'}>
			<h1 className={'page-title text-capitalize'}>Library</h1>
			<ul className="nav cd-nav mb-4">
				<li className={`nav-item ${isTabActive(0)}`} onClick={onSetTabActive.bind(this,0)}>
					<a className="nav-link">Main</a>
				</li>
				<li className={`nav-item ${isTabActive(4)}`} onClick={onSetTabActive.bind(this,4)}>
					<a className="nav-link">Playlist</a>
				</li>
				<li className={`nav-item ${isTabActive(3)}`} onClick={onSetTabActive.bind(this,3)}>
					<a className="nav-link">Artists</a>
				</li>
				<li className={`nav-item ${isTabActive(2)}`} onClick={onSetTabActive.bind(this,2)}>
					<a className="nav-link">Albums</a>
				</li>
			</ul>
			<div className={'search-form'}>
				<input type={'text'} className={'search-input'} placeholder={'Album, artists, or songs'}
				       value={querySearch || ''}
				       onChange={event => {
								 handleQuerySearch(event.target.value)
							 }}
				       onKeyDown={event => {
					       if (event.key === 'Enter' || event.keyCode === 13) {
						       // onEnter(event.currentTarget.value);
						       enterSearch()
					       }
				       }}
				/>
				<i className={'fa fa-search'}></i>
			</div>
			<div className={'content-search'}>
				{ searchData && (
					<>
						{searchData.length > 0 && (
							<div className='timeline-list d-flex flex-column'>
								<div className={'tl-items'}>
									<div className={'tl-content loskeun'}>
										{ searchData && tabActive === 0 && user.authenticated && <CreatePlaylistAct />}
										{ searchData && getMapContentBox(searchData).map((l: any,idx2) => (
											<ContentBox
												{...l} key={`lib-${tabActive}-${idx2}`}/>
										))}
									</div>
								</div>
							</div>
						)}
						{searchData.length === 0 && <EmptySearch />}
					</>
				)
				}
			</div>
		</div>
		<Modal show={showModalPlaylist} onHide={handleClosePlaylist} className={'modal-playlist'}>
			<Modal.Header closeButton closeVariant={'white'}></Modal.Header>
			<Modal.Body>
				<div className={'mb-playlist d-flex justify-content-center align-items-center'}>
					<form className={'mb-playlist-content d-flex flex-column align-items-center'} onSubmit={handleSubmit(onSubmitPlaylist)}>
						<div className={'mb-playlist-title'}>
							Give your playlist a name.
						</div>
						<div className={'mb-playlist-form w-100'}>
							<input type={'text'} className={'mb-playlist-input'}
								{...register("playlist_name", { required: true })}
							/>
							{errors?.['playlist_name'] && (
								<p className="text-danger fst-italic mb-2">
									<>
										Playlist Name is Required
									</>
								</p>
							)}
						</div>
						<div className={'mb-playlist-action d-flex justify-content-between'}>
							<Button white type={'button'} onClick={handleClosePlaylist}>Cancel</Button>
							<Button primary >Create</Button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	</>
}

export default Library
