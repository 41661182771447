import axios, { AxiosError } from 'axios'
import { ApiResponse } from '../typings/api'
import { getToken } from '../utils/common'

const baseUrl = process.env.REACT_APP_IS_LOCAL === 'false' ?(process.env.REACT_APP_API_URL + '/api') : '/api'

export const publicRequest = async (method: 'POST' | 'GET', url: string, data?: any ): Promise<ApiResponse | undefined> => {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const apiUrl = baseUrl + url;
  const dataOrParams = ["GET"].includes(method.toUpperCase()) ? "params" : "data";
  const dataSend = data ?? {}
  const defaultConfig = { method, headers: defaultHeaders, url:apiUrl  };
  const config = {
    ...defaultConfig,
    [dataOrParams]: dataSend,
  };
  return axios(config)
}

export const authenticatedRequest = async (method: 'POST' | 'GET', url: string, data?: any ): Promise<ApiResponse | undefined> => {
  let response;
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${getToken()}`,
  };
  const apiUrl = baseUrl + url;
  const dataOrParams = ["GET"].includes(method.toUpperCase()) ? "params" : "data";
  const dataSend = data ?? {}
  const defaultConfig = { method, headers: defaultHeaders, url:apiUrl  };
  const config = {
    ...defaultConfig,
    [dataOrParams]: dataSend,
  };
  try {
    response = (await axios(config)).data;
  } catch (error: AxiosError | any) {
    if(error?.response?.data){
      throw new Error(error?.response?.data);
    }else{
      throw new Error('Something Wrong. Please Try Again');
    }
  }
  return response
}

export const request = async (method: 'POST' | 'GET' | 'DELETE', url: string, data?: any ): Promise<ApiResponse | undefined> => {
  let response;
  const defaultHeaders: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const token = getToken();
  if(token){
    defaultHeaders["Authorization"] =  `Bearer ${token}`;
  }
  const apiUrl = baseUrl + url;
  const dataOrParams = ["GET"].includes(method.toUpperCase()) ? "params" : "data";
  const dataSend = data ?? {}
  const defaultConfig = { method, headers: defaultHeaders, url:apiUrl  };
  const config = {
    ...defaultConfig,
    [dataOrParams]: dataSend,
  };
  try {
    response = (await axios(config))?.data;
  } catch (error: AxiosError | any) {
    if(error?.response?.data){
      throw new Error(error?.response?.data);
    }else{
      throw new Error('Something Wrong. Please Try Again');
    }
  }
  return response
}

export const getBaseUrl = () => {
  return baseUrl;
}
