import React, {ForwardedRef, ReactElement} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {DropdownMenuProps} from "react-bootstrap/DropdownMenu";
import './DropDownMenuHeader.scss'
import {logout} from "../../services/user.service";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../../typings/state";
import {getToken} from "../../utils/common";

interface ICustomToggle{
	onClick: Function
	children: ReactElement
}
interface ICustomMenu extends DropdownMenuProps{
	children: ReactElement
	style: React.CSSProperties | undefined
	className: string
	'aria-labelledby': any
}
const CustomToggle = React.forwardRef(({ children, onClick }: ICustomToggle, ref: ForwardedRef<any>) => (
	<a
		className={'menu-dropdown'}
		href=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</a>
));

const CustomMenu = React.forwardRef(
	({ children, style, className, 'aria-labelledby': labeledBy }: ICustomMenu, ref: ForwardedRef<any>) => {
		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				{children}
			</div>
		);
	},
);

const DropDownMenuHeader: React.FC = () => {
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const onLogout = async () => {
		await logout();
		document.location.reload()
	}
	const goMember = () => {
		const token = getToken();
		document.location.href = (process.env.REACT_APP_ACCOUNT_URL || '')+`/#/login?_t=${token}&type=dashboard`;
	}
	return(
		<Dropdown>
			<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
				<div className={'menu-dropdown-content'}>
					<div className={'user-info'}>
						<i className={'far fa-user'}></i>
						<span>Hi, {user?.profile?.name}</span>
					</div>
					<i className={'fa fa-chevron-down'}></i>
				</div>
			</Dropdown.Toggle>

			<Dropdown.Menu as={CustomMenu} className={'menu-nav-content'}>
				<Dropdown.Item eventKey="1" onClick={goMember}>Account Settings</Dropdown.Item>
				<Dropdown.Item eventKey="2" onClick={()=>onLogout()}>Logout</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default DropDownMenuHeader
