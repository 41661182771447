import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import './Search.scss'
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {UseAppDispatch} from "../redux/store";
import {useQuery} from "react-query";
import {request} from "../services/api.service";
import {SearchCategoryType, SearchItem} from "../typings/search";
import {ContentBoxType} from "../typings/timeline";
import ContentBox from "../components/pages/ContentBox";
import {PLAYER_DATA, SHOW_MODAL_PREMIUM, SHOW_PLAYER} from "../redux/types";
import useQueryParams from "../hooks/useQueryParams";

const Search: React.FC<RouteComponentProps> = (props) => {
	const dispatch = UseAppDispatch();
	const queryParams = useQueryParams();
	const history = useHistory();
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const { data: categories, refetch: refetchCategories } = useQuery<SearchCategoryType[]>('categories', async () => {
		try{
			const response = await request('GET',`/webplayer/v1/search/category-list`)
			return response?.data as SearchCategoryType[]
		}catch (e) {
			return []
		}
	},{enabled: false})
	const { data: searchData, refetch: refetchSearch } = useQuery<SearchItem[]>('search', async () => {
		try{
			const response = await request('GET',`/webplayer/v1/search`,{
				page: 1,
				q: querySearch,
				type: 'all'
			})
			return response?.data as SearchItem[]
		}catch (e) {
			return []
		}
	},{enabled: false})
	const [querySearch, setQuerySearch] = useState<string | null>(queryParams.get('q') || null)
	useEffect(()=>{
		if(querySearch){
			refetchSearch();
		}
	},[querySearch,user.authenticated])
	useEffect(()=>{
		refetchCategories()
	},[])

	const onEnter = (value: string) => {
		history.push(`/search?q=${value}`)
		setQuerySearch(value !=='' ? value : null)
	}

	const clickCategory = (categoryId: number) => {
		history.push(`/search/category/${categoryId}`)
	}

	const CategoryView = () => {
		return <>
			<div className={'cs-title'}>Browse Category</div>
			<div className={'category-list'}>
				{
					categories?.map((c,index) => (
						<div className={'category-item'} key={`search-cat-${index}`} onClick={()=>clickCategory(c.id)}>
							<img src={c.artwork} />
							<div className={'category-name'}>
								<span>{c.name}</span>
							</div>
						</div>
					))
				}
			</div>
		</>
	}

	const playMusic = (data: {
		artist?: string
		song: string
		thumbnail: string
		url: string
	}) => {
		const playlistOrigin = [{
			artist: data?.artist,
			song: data.song,
			thumbnail: data.thumbnail,
			music_url: data.url,
		}]
		const playlistShuffle = [...playlistOrigin];
		dispatch({
			type: PLAYER_DATA,
			payload: {
				music_data: data,
				playlistOrigin,
				playlistShuffle
			}
		})
		dispatch({ type: SHOW_PLAYER })
		sendMusicPlay(data.url)
	}
	const sendMusicPlay = async (music_slug: string) => {
		await request('POST',`/webplayer/v1/music/play`,{
			"slug" : music_slug,
			"source": 1,
			"is_offline": true,
		})
	}

	const getMapContentBox = (list: SearchItem[]): ContentBoxType[] => {
		return list.map(l => {
			return {
				title: l.name,
				thumbnail: l.picture,
				subtitle: l.type !== 'artist'?l.caption:undefined,
				circle: l.type === 'artist',
				playOverlay: l.type !== 'artist',
				onClick: () => {
					if(!user.authenticated){
						dispatch({
							type: SHOW_MODAL_PREMIUM,
							payload: {
								thumbnail: l.picture
							}
						})
					}else{
						if(l.type === 'playlist'){
							history.push(`/playlist/${l.slug}`)
						}else if(l.type === 'artist'){
							history.push(`/artist/${l.slug}`)
						}else if(l.type === 'album'){
							history.push(`/album/${l.slug}`)
						}else if(l.type === 'song'){
							playMusic({
								artist: l.artist,
								song: l.name,
								thumbnail: l.picture,
								url: l.slug,
							})
						}
					}
				}
			}
		})
	}

	const SearchView = () => {
		return <>
			<div className={'cs-title'}>Search Result</div>
			{ searchData ? (
				<>
					{ searchData.length > 0 && <SearchList />}
					{ searchData.length === 0 && <EmptySearch />}
				</>
			) : null }
		</>
	}

	const SearchList = () => {
		return <>
			<div className={'search-list'}>
				{searchData && getMapContentBox(searchData).map((l: any,idx2) => (
					<ContentBox
						thumbnail={l.thumbnail ?? undefined}
						title={l.title}
						circle={l.circle}
						subtitle={l.subtitle?l.subtitle:undefined}
						onClick={l.onClick?l.onClick:undefined}
						playOverlay={l.playOverlay} key={`cb-search-${idx2}`} />
				))}
				{searchData && (<ul className={'search-see-all'}>
					<li className={'ssa-item'} onClick={()=>goToSearchBy('artist')}>
						<div className={'ssa-label'}>
							See all artists
						</div>
						<i className={'fa fa-chevron-right'}></i>
					</li>
					<li className={'ssa-item'} onClick={()=>goToSearchBy('album')}>
						<div className={'ssa-label'}>
							See all albums
						</div>
						<i className={'fa fa-chevron-right'}></i>
					</li>
					<li className={'ssa-item'} onClick={()=>goToSearchBy('song')}>
						<div className={'ssa-label'}>
							See all songs
						</div>
						<i className={'fa fa-chevron-right'}></i>
					</li>
				</ul>)
				}
			</div>
		</>
	}

	const EmptySearch = () => {
		return <>
			<div className={'empty-search'}>
				<div className={'es-content'}>
					<h3>No Result Found</h3>
					<p>Try using another keyword</p>
				</div>
			</div>
		</>
	}

	const goToSearchBy = (type: string) => {
		history.push(`/search/by/${type}?q=${querySearch}`)
	}

	return <>
		<div className={'main-container'}>
			<h1 className={'page-title'}>Search</h1>
			<div className={'search-form'}>
				<input type={'text'} className={'search-input'}
				       defaultValue={querySearch || ''}
				       onKeyDown={event => {
					       if (event.key === 'Enter' || event.keyCode === 13) {
						       onEnter(event.currentTarget.value);
					       }
				       }}
				/>
				<i className={'fa fa-search'}></i>
			</div>
			<div className={'content-search'}>
				{ categories && !querySearch && <CategoryView /> }
				{ querySearch && <SearchView /> }
			</div>
		</div>
	</>
}

export default Search;
