import './Album.scss';
import React, {useEffect, useState} from "react";
import {RouteComponentProps, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {useQuery} from "react-query";
import {request} from "../services/api.service";
import HeaderContent from "../components/layout/HeaderContent";
import {ArtistAlbumType} from "../typings/artist";
import {secondToMinutes} from "../utils/common";
import ContentList from "../components/pages/ContentList";

const Album: React.FC<RouteComponentProps> = (props) => {
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const params:any  = useParams();
	const [slug, setSlug] = useState<string | null>(null);
	const { data: album, isLoading, refetch } = useQuery<ArtistAlbumType | undefined>(['album-detail',slug], async () => {
		if(slug){
			try{
				const response = await request('GET',`/webplayer/v1/album/detail/${slug}`)
				return response?.data as ArtistAlbumType
			}catch (e) {
				return undefined
			}
		}else{
			return undefined
		}
	})
	useEffect(() => {
		if(params?.slug){
			setSlug(decodeURI(params?.slug))
		}
	},[])
	const getInfo = () => {
		if(album){
			let infos = [album?.year, `${album?.content.length} Songs`]
			const totalDuration = album.content.reduce((total, obj)=>{
				return total + obj.duration;
			}, 0)
			infos.push(`${secondToMinutes(totalDuration)} min`)
			return infos
		}
		return undefined
	}
	return <>
		<div className={'main-container-full'}>
			<HeaderContent category={'Album'} title={album?.name} cover={album?.cover} user_name={album?.artist} info={getInfo()} />
			<div className={'album-content'}>
				{album?.content &&  <ContentList
					content={album?.content}
					type={'album'}
					artist_slug={album?.artist_slug}
					favorited={album?.is_favorited}
					album_slug={album?.slug}
					share_url={album?.share_url}
				/>}
			</div>
		</div>
	</>
}
export default Album
