import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import './SignUp.scss'
import placeholder from '../assets/images/placeholder.png'
import Button from "../components/layout/UI/Button";
import {useForm} from "react-hook-form";
import {UseAppDispatch} from "../redux/store";
import Input from "../components/layout/UI/Input";
import {REMOVE_USER_SOCIAL, UNLOADING_UI} from "../redux/types";
import {Options} from "../typings/common";
import Select from "../components/layout/UI/Select";
import {EmailValidation} from "../utils/common";
import {useQuery} from "react-query";
import {request} from "../services/api.service";
import {signUp} from "../services/user.service";
import {LoginSocialType, SignUpType} from "../typings/user";
import {Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
const genderOptions: Options[] = [
	{
		value: 'male',
		label: 'Male',
	},
	{
		value: 'female',
		label: 'Female',
	},
]
const SignUp: React.FC<RouteComponentProps> = (props) => {
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const dispatch = UseAppDispatch();
	const { register, handleSubmit, formState: { errors }, getValues, watch, setValue } = useForm();
	const { data: countries, isLoading } = useQuery<any[], boolean>('countries', async () => {
		try{
			const response = await request('GET',`/country`)
			return response?.data as any[]
		}catch (e) {
			return []
		}
	})
	const [countryList, setCountryList] = useState<Options[]>([])
	const [avatarFile, setAvatarFile] = useState(null);
	const [avatar, setAvatar] = useState(null);
	const [errorSignUp, setErrorSignUp] = useState<{error: boolean,msg: string}>({error: false, msg:''})
	const [showModalSuccess, setShowModalSuccess] = useState(false);
	const [isSocial, setIsSocial] = useState(false);
	const [socialData, setSocialData] = useState<LoginSocialType | undefined>(undefined);
	useEffect(() => {
		if(countries){
			setCountryList(countries.map((c: any) => {
				return {
					value: c.code,
					label: c.name,
				}
			}))
		}
	},[countries])
	useEffect(() => {
		if(avatarFile){
			// @ts-ignore
			setAvatar(URL.createObjectURL(avatarFile))
		}else{
			setAvatar(null)
		}
	},[avatarFile])
	useEffect(() => {
		if(user?.social){
			setIsSocial(true)
			setSocialData(user?.social)
			setValue('email',user?.social?.email)
			setValue('first_name',getFirstName(user.social.name))
			setValue('last_name',getLastName(user.social.name))
			dispatch({type: REMOVE_USER_SOCIAL})
			dispatch({type: UNLOADING_UI})
		}
	},[user?.social])
	const changeAvatar = (event: any) => {
		if(event.target.files.length > 0){
			const file = event.target.files[0]
			setAvatarFile(file)
		}
	}
	const removeAvatar = () => {
		setAvatarFile(null)
	}
	const ShowMessageSignUpLogin = () => {
		if(errorSignUp.error){
			return <>
				<div className={'alert alert-danger mb-3'}>
					<p>{errorSignUp.msg}</p>
				</div>
			</>
		}
		return <></>
	}
	const onSubmit = async (data: any) => {
		try{
			let dataSubmit: SignUpType = {
				method : 'email',
				first_name : data.first_name,
				last_name: data.last_name,
				gender: data.gender,
				email: data.email,
				password: data.password,
				country_code: data.country_code,
			}
			if(isSocial && socialData){
				dataSubmit.password = '-';
				dataSubmit.method = socialData.method
				dataSubmit.token = socialData.token
				dataSubmit.uid = socialData.uid
			}
			if(avatarFile){
				const file = await toBase64() as string;
				dataSubmit = {...dataSubmit,avatar: file}
			}
			await signUp(dataSubmit, dispatch)
			setErrorSignUp({error: false,msg: ''})
			if(!isSocial){
				setShowModalSuccess(true)
			}else{
				goBack();
			}

			// setErrorLogin({error:false,msg: ''})
		}catch (e: Error | any) {
			dispatch({ type: UNLOADING_UI })
			setErrorSignUp({error:true,msg: e.message})
		}

	}

	const getFirstName = (name: string) => {
		return name.split(' ')[0];
	}
	const getLastName = (name: string) => {
		let lastName = '';
		const nameArray = name.split(' ');
		if (nameArray.length > 1) {
			nameArray.shift();
			for (let i = 0; i < nameArray.length; i++) {
				lastName += `${nameArray[i]} `;
			}
			return lastName;
		}
		return name;
	}

	const toBase64 = () => {
		return new Promise((resolve, reject) => {
			if(avatarFile){
				const reader = new FileReader();
				reader.readAsDataURL(avatarFile);
				reader.onload = () => resolve(reader.result);
				reader.onerror = error => reject(error);
			}else{
				reject('')
			}
		})
	}

	const goBack = () =>{
		props.history.push('/')
	}

	return (
	<>
		<div className={'sign-up'}>
			<div className={'page-header'}>
				<a className={'back'} onClick={goBack}>
					<i className={'fa fa-arrow-left'}></i>
				</a>
				<h3>Create Account</h3>
			</div>
			<div className={'sign-up-form'}>
				<form className={'form-wrapper'} onSubmit={handleSubmit(onSubmit)}>
					<div className={'avatar mb-3'}>
						<img src={avatar ?? placeholder} className={'mb-3'} />
						<div className={'position-relative'}>
							<input type="file" name={'avatar'} className={'avatar-file'} id="upload" onChange={changeAvatar.bind(this)}/>
							<Button type={'button'} white className={'position-relative'}>
								<>
									<i className={'fa fa-camera text-white'}></i>
									Choose Picture
								</>
							</Button>
						</div>

					</div>
					<div className={'form-group mb-3'}>
						<Input placeholder={'First Name'} Icon={'far fa-user'}

						       name='first_name'
						       register={register}
						       errors={errors}
						       rules={{
							       required: "First Name is required",
						       }}
						/>
					</div>
					<div className={'form-group mb-3'}>
						<Input placeholder={'Last Name'} Icon={'far fa-user'}

						       name='last_name'
						       register={register}
						       errors={errors}
						       rules={{
							       required: "Last Name is required",
						       }}
						/>
					</div>
					<div className={'form-group mb-3'}>
						<Input placeholder={'Email'} Icon={'far fa-envelope'}

						       name='email'
						       register={register}
						       errors={errors}
						       rules={{
							       required: "Email is required",
							       validate:EmailValidation
						       }}
						       readonly={isSocial}
						/>
					</div>
					<div className="form-group mb-3">
						<Select
							placeholder='Select Gender'
							name='gender'
							register={register}
							errors={errors}
							block={true}
							rules={{
								required: "Gender is required",
							}}
							options={genderOptions}
						/>
					</div>
					<div className="form-group mb-3">
						<Select
							placeholder='Select Country of Residence'
							name='country_code'
							register={register}
							errors={errors}
							block={true}
							rules={{
								required: "Country of Residence is required",
							}}
							options={countryList}
						/>
					</div>
					{
						!isSocial ? (
							<>
								<div className={'form-group mb-3'}>
									<Input placeholder={'Password'} Icon={'fa fa-lock'}
									       name='password'
									       type='password'
									       register={register}
									       errors={errors}
									       rules={{
										       required: "Password is required",
									       }}
									/>
								</div>
								<div className={'form-group mb-5'}>
									<Input placeholder={'Confirm Password'} Icon={'fa fa-lock'}
									       name='confirm_password'
									       type='password'
									       register={register}
									       errors={errors}
									       rules={{
										       required: "Confirm Password is required",
										       validate: (val: string) => {
											       if (watch('password') !== val) {
												       return "Your passwords do no match";
											       }
										       },
									       }}
									/>
								</div>
							</>
						) : null
					}
					<ShowMessageSignUpLogin />
					<div className={'form-group mb-3 d-flex justify-content-center'}>
						<Button primary className={'btn-save-profile'}>Save Profile</Button>
					</div>
				</form>
			</div>
		</div>
		<Modal show={showModalSuccess} onHide={() => setShowModalSuccess(false)} className={'modal-success'}>
			<Modal.Body>
				<div className={'d-flex justify-content-center align-items-center'}>
					<div className={'d-flex flex-column align-items-center'} style={{
						padding: '55px 96px'
					}}>
						<h3 className={'text-white mb-3'}>
							Success
						</h3>
						<p className={'text-primary mb-3 text-center fs-7'}>
							Please check your inbox/ spam/ promotions/ social tabs and click the activation link. If you can’t find the email, try searching “MusicVerse” on your mailbox
						</p>
						<Button onClick={goBack} primary>OK</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
	)
}
export default SignUp
