export const SET_AUTHENTICATED='SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED='SET_UNAUTHENTICATED';
export const SET_USER='SET_USER';
export const SET_USER_SOCIAL='SET_USER_SOCIAL';
export const REMOVE_USER_SOCIAL='REMOVE_USER_SOCIAL';
//UI reducer types
export const SET_ERRORS='SET_ERRORS';
export const LOADING_UI='LOADING_UI';
export const UNLOADING_UI='UNLOADING_UI';
export const CLEAR_ERRORS='CLEAR_ERRORS';
export const SHOW_MODAL_LOGIN='SHOW_MODAL_LOGIN';
export const HIDE_MODAL_LOGIN='HIDE_MODAL_LOGIN';
export const SHOW_MODAL_PREMIUM='SHOW_MODAL_PREMIUM';
export const HIDE_MODAL_PREMIUM='HIDE_MODAL_PREMIUM';
export const GO_PREMIUM='GO_PREMIUM';
export const UNGO_PREMIUM='UNGO_PREMIUM';

export const SHOW_PLAYER='SHOW_PLAYER';
export const HIDE_PLAYER='SHOW_PLAYER';
export const PLAYER_DATA='PLAYER_DATA';
export const PLAYER_ENDED='PLAYER_ENDED';

