import React, {useEffect, useState} from "react";
import {RouteComponentProps, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {useQuery, useQueryClient} from "react-query";
import {request} from "../services/api.service";
import {PlaylistType} from "../typings/playlist";
import HeaderContent from "../components/layout/HeaderContent";
import {secondToMinutes} from "../utils/common";
import ContentList from "../components/pages/ContentList";
import SearchSong from "../components/pages/SearchSong";
import './Playlist.scss'

const Playlist: React.FC<RouteComponentProps> = (props) => {
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const params:any  = useParams();
	const [slug, setSlug] = useState<string | null>(null);
	const [firstLoad, setFirstLoad] = useState<boolean>(true);
	const [showSearch, setShowSearch] = useState<boolean>(false);
	const { data: playlist, isLoading,isFetched, refetch, isPreviousData, remove } = useQuery<PlaylistType | undefined, boolean>(['playlist-detail',slug], async () => {
		if(slug){
			try{
				const response = await request('GET',`/webplayer/v1/lib/playlist/${user.authenticated?'content':'detail'}/${slug}`)
				return response?.data as PlaylistType
			}catch (e) {
				return undefined
			}
		}else{
			return undefined
		}
	},{enabled: false})
	useEffect(() => {
		if(params?.slug){
			setSlug(decodeURI(params?.slug))
		}
		setShowSearch(false)
		setFirstLoad(true)
	},[])
	useEffect(() => {
		if((user.authenticated || user.authenticated === false) && slug){
			refetch().then((r)=>{
				setShowSearch(r?.data?.content?.length === 0)
				setFirstLoad(false)
			})
		}
	},[user.authenticated,slug])
	const getInfo = () => {
		if(playlist){
			let infos = [`${playlist?.content.length} Songs`]
			const totalDuration = playlist.content.reduce((total, obj)=>{
				return total + obj.duration;
			}, 0)
			infos.push(`${secondToMinutes(totalDuration)} min`)
			return infos
		}
		return undefined
	}
	const handleHideSearch = () => setShowSearch(false)
	const onAddSong = () => {
		refetch();
	}
	return <>
		<div className={'main-container-full'}>
			<HeaderContent category={'Playlist'} title={playlist?.title} covers={playlist?.covers} info={getInfo()} />
			<div className={'playlist-content'}>
				{playlist?.content &&  <ContentList content={playlist?.content} type={'playlist'} playlist_slug={playlist?.slug} playlist_type={playlist?.type} onRemoveFromPlaylist={onAddSong} share_url={playlist?.type !==1 ? playlist?.share_url : undefined}/>}
				{showSearch && user.authenticated && <SearchSong onAddSong={onAddSong} onHide={handleHideSearch} slug={playlist?.slug}/>}
			</div>
		</div>
	</>
}

export default Playlist
