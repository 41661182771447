import {
	CLEAR_ERRORS,
	HIDE_MODAL_LOGIN, HIDE_MODAL_PREMIUM,
	LOADING_UI,
	SET_AUTHENTICATED,
	SET_ERRORS,
	SET_USER, SET_USER_SOCIAL,
	UNLOADING_UI
} from "../redux/types";
import {publicRequest, request} from "./api.service";
import {storageKeyToken, storageKeyUser} from "../utils/constant";
import {LoginSocialType, SignUpType} from "../typings/user";
import {AxiosError} from "axios";


export const login = async (data: { email: string; password: string; dispatch: any }, dispatch: any) => {
	dispatch({ type: LOADING_UI })
	try{
		const resp: any = await publicRequest('POST','/webplayer/v1/auth/login',data)
		const dataRes = resp?.data?.data
		localStorage.setItem(storageKeyToken,dataRes.access_token)
		localStorage.setItem(storageKeyUser,JSON.stringify(dataRes.user))
		dispatch({ type: CLEAR_ERRORS });
		dispatch({
			type:SET_AUTHENTICATED
		})
		dispatch({
			type:SET_USER,
			payload:{
				profile:dataRes.user,
				token:dataRes.access_token
			}
		})
		dispatch({ type: HIDE_MODAL_LOGIN });

	}catch (e: Error | any) {
		dispatch({
			type: SET_ERRORS,
			payload: {
				error:true,
				message: e.message
			}
		});
		if(e?.response?.data?.message){
			throw new Error(e?.response?.data?.message);
		}else{
			throw new Error('Something Wrong. Please Try Again');
		}
	}
	dispatch({ type: UNLOADING_UI })
}

export const loginSocial = async (data: LoginSocialType, dispatch: any) => {
	dispatch({ type: LOADING_UI })
	try{
		const resp: any = await publicRequest('POST','/webplayer/v1/auth/connect-socmed',data)
		const dataRes = resp?.data?.data
		if(dataRes === null){
			dispatch({
				type: SET_USER_SOCIAL,
				payload: data
			});
			dispatch({
				type: SET_ERRORS,
				payload: {
					error:true,
					message: 'Go To Signup'
				}
			});
			dispatch({ type: UNLOADING_UI })
			return false;
		}else{
			localStorage.setItem(storageKeyToken,dataRes.access_token)
			localStorage.setItem(storageKeyUser,JSON.stringify(dataRes.user))
			dispatch({ type: CLEAR_ERRORS });
			dispatch({
				type:SET_AUTHENTICATED
			})
			dispatch({
				type:SET_USER,
				payload:{
					profile:dataRes.user,
					token:dataRes.access_token
				}
			})
			dispatch({ type: HIDE_MODAL_LOGIN });
			dispatch({ type: UNLOADING_UI })
			return true;
		}
	}catch (e: AxiosError | any) {
		dispatch({ type: UNLOADING_UI })
		dispatch({
			type: SET_ERRORS,
			payload: {
				error:true,
				message: e.message
			}
		});
		if(e?.response?.data?.message){
			throw new Error(e?.response?.data?.message);
		}else{
			throw new Error('Something Wrong. Please Try Again');
		}
	}
}

export const signUp = async (data: SignUpType, dispatch: any) => {
	dispatch({ type: LOADING_UI })
	try{
		const resp: any = await publicRequest('POST','/webplayer/v1/auth/register',data)
		const dataRes = resp?.data?.data
		if(data.method !== 'email'){
			localStorage.setItem(storageKeyToken,dataRes.access_token)
			localStorage.setItem(storageKeyUser,JSON.stringify(dataRes.user))
			dispatch({
				type:SET_AUTHENTICATED
			})
			dispatch({
				type:SET_USER,
				payload:{
					profile:dataRes.user,
					token:dataRes.access_token
				}
			})
			dispatch({ type: HIDE_MODAL_LOGIN })
			dispatch({ type: HIDE_MODAL_PREMIUM })
		}
	}catch (e: Error | any) {
		dispatch({
			type: SET_ERRORS,
			payload: {
				error:true,
				message: e.message
			}
		});
		if(e?.response?.data?.message){
			throw new Error(e?.response?.data?.message);
		}else{
			throw new Error('Something Wrong. Please Try Again');
		}
	}
	dispatch({ type: UNLOADING_UI })
}

export const logout = async ()  => {
	try{
		const resp = await request('POST','/webplayer/v1/auth/logout',{})
		localStorage.removeItem(storageKeyToken)
		localStorage.removeItem(storageKeyUser)
	}catch (e: Error | any) {
	}
}
