import './Home.scss'
import './NotFound.scss'

const NotFound = () => {
	return <>
		<div className={'main-container'}>
			<div className={'d-flex w-100 align-items-center justify-content-center'} style={{height: '75vh'}}>
				<div className={'d-flex flex-column align-items-center'} style={{width:'40.78vw'}}>
					<span className={'nf-desc-big'}>Oops! It seems like you've wandered off the path of heavenly melodies.</span>
					<span className={'nf-desc-small'}>The page you are looking for is either doesn’t exist or has been removed. We suggest you back to home</span>
				</div>
			</div>
		</div>
	</>
}
export default NotFound
