import {
	SHOW_PLAYER,
	PLAYER_DATA, HIDE_PLAYER, PLAYER_ENDED
} from "../types";
import {ActionType, InitialStatePlayer} from '../../typings/state'
const initialState: InitialStatePlayer = {
	show: false,
	ended: false,
	source: 'single',
	playlist_slug: '',
	playlistShuffle: [],
	playlistOrigin: [],
};
export default function (state = initialState, action: ActionType) {
	switch (action.type) {
		case SHOW_PLAYER:
			return {
				...state,
				show: true,
			};
		case HIDE_PLAYER:
			return {
				...state,
				show: false,
			};
		case PLAYER_DATA:
			return {
				...state,
				source: 'single',
				playlist_slug: '',
				...action.payload,
				ended: false
			};
		case PLAYER_ENDED:
			return {
				...state,
				ended: true
			};
		default:
			return state;
	}
}
