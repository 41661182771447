import React, {useState} from "react";
import {useQuery} from "react-query";
import {SearchSongItem} from "../../typings/search";
import {request} from "../../services/api.service";
import './SearchSong.scss'
import addIcon from '../../assets/images/icon/add-icon.svg'
import {toast} from "react-toastify";

interface ISearchSong {
	onAddSong: Function
	onHide: Function
	slug?: string
}
const SearchSong = (props: ISearchSong) => {
	const [querySearch, setQuerySearch] = useState<string>('')
	const [songItems, setSongItems] = useState<SearchSongItem[]>([])
	const { data: searchData, refetch: refetchSearch } = useQuery<SearchSongItem[]>('search-song-playlist', async () => {
		try{
			const response = await request('GET',`/webplayer/v1/lib/playlist/search-music`,{
				q: querySearch,
				page: 1,
			})
			return response?.data as SearchSongItem[]
		}catch (e) {
			return []
		}
	},{enabled: false})
	const handleQuerySearch = (value: string) => {
		setQuerySearch(value);
	}
	const enterSearch = () => {
		refetchSearch().then(res => {
			if(res?.data){
				setSongItems(res.data)
			}
		})
	}
	const addSongToPlaylist = async (musicSlug: string) => {
		const res = await request('POST',`/webplayer/v1/lib/playlist/add-music`,{
			music_slug: musicSlug,
			playlist_slug: props.slug
		})
		props.onAddSong();
		toast.success("Added to your playlist!", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
	}
	return <>
		<div className={'search-song-playlist'}>
			<div className={'ssp-header d-flex justify-content-between align-items-center'}>
				<h4 className={'ssp-title'}>Let’s find some songs for your playlist.</h4>
				<i className={'fa fa-times cursor-pointer'} onClick={()=>{props.onHide()}}></i>
			</div>
			<div className={'search-form'}>
				<input type={'text'} className={'search-input'} placeholder={'Search song'}
				       value={querySearch || ''}
				       onChange={event => {
					       handleQuerySearch(event.target.value)
				       }}
				       onKeyDown={event => {
					       if (event.key === 'Enter' || event.keyCode === 13) {
						       enterSearch()
					       }
				       }}
				/>
				<i className={'fa fa-search'}></i>
			</div>
			<div className={'ssp-content d-flex flex-column w-100'}>
				{songItems.map((s,idx)=>(
					<div className={'ssp-content-item d-flex justify-content-between align-items-center'}>
						<div className={'sspc-item-info d-flex align-items-center'}>
							<img src={s.picture} className={'sspc-info-image'}/>
							<div className={'d-flex flex-column'}>
								<span className={'sspc-info-name'}>{s.name}</span>
								<span className={'sspc-info-artist'}>{s.artist}</span>
							</div>
						</div>
						<div className={'sspc-item-action'} onClick={()=>addSongToPlaylist(s.slug)}>
							<img src={addIcon} />
						</div>
					</div>
				))}
			</div>
		</div>
	</>
}

export default SearchSong
