import React, {useEffect, useState} from "react";
import {ContentType} from "../../typings/content";
import './ContentList.scss'
import playIcon from '../../assets/images/icon/play-icon.png'
import shareIcon from '../../assets/images/icon/share-icon.svg'
import ellipsisIcon from '../../assets/images/icon/ellipsis-icon.svg'
import loveIcon from '../../assets/images/icon/love-icon.svg'
import {ReactComponent as LoveIcon} from '../../assets/images/icon/love-icon.svg';
import playingIcon from '../../assets/images/icon/playing.svg'
import playSmallIcon from '../../assets/images/icon/play-small.svg'
import {copyValue, secondsToMinutesAndSeconds, shuffle} from "../../utils/common";
import DropDownMenu from "../layout/UI/DropDownMenu";
import {DropDownLinkType} from "../../typings/common";
import {UseAppDispatch} from "../../redux/store";
import {PLAYER_DATA, SHOW_PLAYER} from "../../redux/types";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {request} from "../../services/api.service";
import ModalAddPlaylist from "./ModalAddPlaylist";
import playlist from "../../pages/Playlist";
import {useSelector} from "react-redux";
import {InitialStatePlayer} from "../../typings/state";
import {sendPlayTrack} from "../../services/song.service";

interface IContentList {
	content: ContentType[]
	type: string
	favorited?: boolean
	artist_slug?: string
	album_slug?: string
	playlist_slug?: string
	share_url?: string
	playlist_type?: number
	onRemoveFromPlaylist?: Function
}

const ContentList: React.FC<IContentList> = (props) => {
	const history = useHistory();
	const playerState = useSelector((state: {player: InitialStatePlayer}) => state.player)
	const [isFavorited, setIsFavorited] = useState<boolean>(props.favorited ? true : false)
	const [contentList, setContentList] = useState<ContentType[]>([])
	const [showAddPlaylist, setShowAddPlaylist] = useState(false)
	const [musicSlug, setMusicSlug] = useState('')
	const dispatch = UseAppDispatch();
	useEffect(()=>{
		if(props.content){
			setContentList(props.content)
		}
	},[props.content])
	useEffect(()=>{
		if(playerState?.music_data && contentList.length > 0){
			const el = document.getElementById(`cl-items-${playerState.music_data.url}`)
			if(el){
				const clItems = document.querySelectorAll('.cl-items');
				clItems.forEach(c => {
					c.classList.remove('playing')
				})
				el.classList.add('playing')
			}
		}
	},[playerState?.music_data,contentList])
	const getLinksContentList = () => {
		let links = []
		if(props.type === 'album'){
			links = [{
				label: isFavorited? 'Remove from Library' : 'Add to Library',
				onClick: () => {
					addToLibraryAlbum()
				}
			},{
				label: 'View Artist',
				onClick: () => {
					if(props.artist_slug){
						history.push(`/artist/${props.artist_slug}`)
					}
				}
			}]
		}else{
			links = [{
				label: 'Delete Playlist',
				onClick: () => {
					removePlaylist()
				}
			}]
		}

		return links as DropDownLinkType[];
	}

	const addToLibraryAlbum = async () => {
		const res = await request('POST',`/webplayer/v1/album/${isFavorited ? 'remove-from': 'add-to'}-library`,{
			album_slug: props?.album_slug
		})
		toast.success(!isFavorited ? "Added to your library!" : "Removed from your library!", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
		setIsFavorited(!isFavorited)
	}

	const findContentIndexBySlug = (slug: string) => {
		return contentList.findIndex(c => c.slug === slug)
	}

	const updateFavoriteContentBySlug = (slug: string, status: boolean) => {
		const index = findContentIndexBySlug(slug);
		const contentListClone = [...contentList]
		contentListClone[index].is_favorited = status
		setContentList([...contentListClone])
	}

	const addToFavorite = async (music_slug: string) => {
		const res = await request('POST', `/webplayer/v1/lib/playlist/add-to-favorite`, {
			music_slug
		})
		updateFavoriteContentBySlug(music_slug, true);
		toast.success("Added to your favorite!", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
	}

	const removeToFavorite = async (music_slug: string) => {
		const res = await request('POST',`/webplayer/v1/lib/playlist/remove-from-favorite`,{
			music_slug
		})
		updateFavoriteContentBySlug(music_slug, false);
		toast.success("Removed from your favorite", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
	}

	const handleLike = (slug: string, status: boolean) => {
		if(status){
			removeToFavorite(slug)
		}else{
			addToFavorite(slug)
		}
	}

	const removeFromPlaylist = async (music_slug: string) => {
		const res = await request('POST',`/webplayer/v1/lib/playlist/remove-music`,{
			music_slug,
			playlist_slug: props.playlist_slug
		})
		toast.success("Song has been removed from playlist!", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
		if(props?.onRemoveFromPlaylist){
			props.onRemoveFromPlaylist()
		}
	}
	const addToLibraryPlaylist = async () => {
		const res = await request('POST',`/webplayer/v1/lib/playlist/add-to-library`,{
			playlist_slug: props.playlist_slug
		})
		toast.success("Added to your library!", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
	}

	const removePlaylist = async () => {
		const res = await request('DELETE',`/webplayer/v1/lib/playlist/delete/${props.playlist_slug}`)
		toast.success("Playlist deleted!", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
		history.push('/library')
	}
	const sendMusicPlay = async (music_slug: string) => {
		await request('POST',`/webplayer/v1/music/play`,{
			"slug" : music_slug,
			"source" : props.type === 'album' ? 2 : 3,
			"source_slug" : props.type === 'album' ? props.album_slug : props.playlist_slug,
			"is_offline": true,
		})
	}
	const getLinksContentItem = (content: ContentType) => {
		return [
			{
				label: content.is_favorited ? 'Remove from Favorite' : 'Add to Favorite',
				onClick: () => {
					if(content.is_favorited){
						removeToFavorite(content.slug)
					}else{
						addToFavorite(content.slug)
					}
				}
			},
			{
				label: 'Add to Playlist',
				onClick: () => {
					// console.log(content.slug, content.title)
					setMusicSlug(content.slug)
					setShowAddPlaylist(true)
				}
			},
			{
				label: 'View Artist',
				onClick: () => {
					history.push(`/artist/${content.artist_slug}`)
				}
			},
			(props.type === 'playlist' && props?.playlist_type === 1 ? {
				label: 'Remove from Playlist',
				onClick: () => {
					removeFromPlaylist(content.slug);
				}
			}: {})
		] as DropDownLinkType[]
	}

	const onRowClick = (event: React.MouseEvent<HTMLTableRowElement>, contentIdx: number, slug: string) => {
		switch (event.detail) {
			case 1:
				const el = document.getElementById(`cl-items-${slug}`)
				if(el){
					if(el.classList.contains('clicked')){
						el.classList.remove('clicked')
					}else{
						const clItems = document.querySelectorAll('.cl-items');
						clItems.forEach(c => {
							c.classList.remove('clicked')
						})
						el.classList.add('clicked')
					}
				}
				break;
			case 2:
				playMusic(contentIdx)
				break;
		}
	}

	const playMusic = (contentIdx: number) => {
		const songData = props.content[contentIdx]
		const playlistOrigin = props.content.map((c, idx) => {
			return {
				artist: c.artist,
				song: c.title,
				thumbnail: c.thumbnail,
				music_url: c.slug,
				duration: c.duration,
				t: c?.t,
			}
		})
		const playlistShuffle = shuffle([...playlistOrigin]);

		dispatch({
			type: PLAYER_DATA,
			payload: {
				music_data: {
					artist: songData.artist,
					song: songData.title,
					thumbnail: songData.thumbnail,
					url: songData.slug,
					duration: songData.duration,
					t: songData?.t,
				},
				playlistOrigin,
				playlistShuffle,
				source : props.type,
				playlist_slug : props.type === 'playlist' && [2,3].includes(props.playlist_type || 0) ? props.playlist_slug : '',
			}
		})
		dispatch({ type: SHOW_PLAYER })
		if(props.type === 'album' || (props.type === 'playlist' && [2,3].includes(props.playlist_type || 0))){
			sendMusicPlay(songData.slug)
		}else{
			sendPlayTrack(songData.slug, 'single')
		}
	}

	const share = () => {
		if(props?.share_url){
			copyValue(props?.share_url);
			toast.success("Link copied to clipboard!", {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
				hideProgressBar: false,
				pauseOnHover: false,
			});
		}
	}

	const bigPlayClick = () => {
		const playlistOrigin = props.content.map((c, idx) => {
			return {
				artist: c.artist,
				song: c.title,
				thumbnail: c.thumbnail,
				music_url: c.slug,
				duration: c.duration,
				t: c?.t
			}
		})
		const playlistShuffle = shuffle([...playlistOrigin]);
		const songData = playlistOrigin[0]
		dispatch({
			type: PLAYER_DATA,
			payload: {
				music_data: {
					artist: songData?.artist,
					song: songData.song,
					thumbnail: songData.thumbnail,
					url: songData.music_url,
					duration: songData.duration,
					t: songData?.t,
				},
				playlistOrigin,
				playlistShuffle
			}
		})
		dispatch({ type: SHOW_PLAYER })
		if(props.type === 'album' || (props.type === 'playlist' && [2,3].includes(props.playlist_type || 0))){
			sendMusicPlay(songData.music_url)
		}
	}

	return <>
		<div className={'content-list-container'}>
			<div className={'cl-setting'}>
				{props?.content?.length > 0 && <img className={'play'} src={playIcon} onClick={bigPlayClick} />}
				{props?.share_url && <img className={'cl-setting-icon cursor-pointer'} src={shareIcon} onClick={share} />}
				{(props?.album_slug || (props?.playlist_slug && props?.playlist_type === 1)) && <DropDownMenu links={getLinksContentList()}>
          <img src={ellipsisIcon} className={'icon-dd-large'}/>
        </DropDownMenu>}
			</div>
			<div className={'cl-contents'}>
				<table className="table table-borderless">
					<thead>
						<tr>
							<td width={'64px'}>#</td>
							<td>Title</td>
							{props.type === 'playlist' && <td>Album</td>}
							<td width={'137px'}>Duration</td>
						</tr>
					</thead>
					<tbody>
					{
						contentList.map((c, idx) => (
							<tr className={'cl-items'} key={`cl-items-${idx}`} id={`cl-items-${c.slug}`} onClick={(event)=>onRowClick(event, idx, c.slug)}>
								<td>
									<div className={'d-flex justify-content-center'}>
										<span className={'cl-items-no'}>{idx+1}</span>
										<img src={playingIcon} className={'cl-items-playing'} alt={'icon'}/>
										<img src={playSmallIcon} className={'cl-items-play'} alt={'icon'} onClick={()=>playMusic(idx)}/>
									</div>
								</td>
								<td className={'cl-items-info'}>
									<div className={'cl-info d-flex align-items-center'}>
										<img src={c.thumbnail} className={'cl-info-cover'}/>
										<div className={'d-flex flex-column'}>
											<span className={'cl-info-title'}>{c.title}</span>
											<span className={'cl-info-caption'}>{c.genre}</span>
										</div>
									</div>
								</td>
								{props.type === 'playlist' && <td>{c.caption}</td>}
								<td>
									<div className={'cl-items-action'}>
										<span>{secondsToMinutesAndSeconds(c.duration)}</span>
										<LoveIcon className={`cl-action-icon cursor-pointer ${c.is_favorited ? 'liked' : ''}`} onClick={()=>handleLike(c.slug,c.is_favorited)} />
										{/*<img src={loveIcon} className={'cl-action-icon cursor-pointer'}/>*/}
										<DropDownMenu links={getLinksContentItem(c)}>
											<img src={ellipsisIcon} className={'icon-dd-small'}/>
										</DropDownMenu>
									</div>
								</td>
							</tr>
						))
					}
					</tbody>
				</table>
			</div>
		</div>
		{showAddPlaylist && <ModalAddPlaylist music_slug={musicSlug}  onHide={()=>setShowAddPlaylist(false)} /> }
	</>
}

export default ContentList
