import React, {ForwardedRef, ReactElement} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {DropdownMenuProps} from "react-bootstrap/DropdownMenu";
import './DropDownMenu.scss'
import {DropDownLinkType} from "../../../typings/common";

interface ICustomToggle{
	onClick: Function
	children: ReactElement
}
interface ICustomMenu extends DropdownMenuProps{
	children: ReactElement
	style: React.CSSProperties | undefined
	className: string
	'aria-labelledby': any
}
interface IDropDownMenu {
	links: DropDownLinkType[]
	children: ReactElement
}
const CustomToggle = React.forwardRef(({ children, onClick }: ICustomToggle, ref: ForwardedRef<any>) => (
	<a
		className={'menu-dropdown'}
		href=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</a>
));

const CustomMenu = React.forwardRef(
	({ children, style, className, 'aria-labelledby': labeledBy }: ICustomMenu, ref: ForwardedRef<any>) => {
		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				{children}
			</div>
		);
	},
);

const DropDownMenu:  React.FC<IDropDownMenu> = (props) => {
	return(
		<Dropdown>
			<Dropdown.Toggle as={CustomToggle} >
				{props.children}
			</Dropdown.Toggle>

			<Dropdown.Menu as={CustomMenu} className={'menu-nav-content'}>
				{ props.links.map((l,idx) => (
					<Dropdown.Item eventKey={idx+1} key={`dd-menu-${idx}`} onClick={(event)=>l.onClick()}>{l.label}</Dropdown.Item>
				)) }
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default DropDownMenu
