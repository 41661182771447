import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {UseAppDispatch} from "../redux/store";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {useInfiniteQuery, useQuery} from "react-query";
import {ContentBoxType, ModalPremiumType, TimelineType} from "../typings/timeline";
import {request} from "../services/api.service";
import {SearchCategoryType, SearchItem} from "../typings/search";
import './Home.scss'
import {PLAYER_DATA, SHOW_MODAL_LOGIN, SHOW_MODAL_PREMIUM, SHOW_PLAYER} from "../redux/types";
import ContentBox from "../components/pages/ContentBox";
import { useInView } from 'react-intersection-observer'
import {ApiDataResponse} from "../typings/api";

const SearchCategory: React.FC<RouteComponentProps> = (props) => {
	const { ref, inView } = useInView()
	const dispatch = UseAppDispatch();
	const history = useHistory();
	const params:any  = useParams();
	const [categoryId, setCategoryId] = useState<number | null>(null);
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const [category, setCategory] = useState<SearchCategoryType|null>(null);
	const { data: categories, refetch: refetchCategories } = useQuery<SearchCategoryType[]>('categories', async () => {
		try{
			const response = await request('GET',`/webplayer/v1/search/category-list`)
			return response?.data as SearchCategoryType[]
		}catch (e) {
			return []
		}
	},{enabled: false})
	const { data, isLoading, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(['search-category',categoryId], async ({ pageParam = 1 }) => {
		if(categoryId){
			try{
				const response = await request('GET',`/webplayer/v1/search${user.authenticated?'-member':''}/category`,{
					page:pageParam,
					type:'all',
					id:categoryId
				})
				return response as ApiDataResponse
			}catch (e) {
				return []
			}
		}else{
			return []
		}

	},{
		getPreviousPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		getNextPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		enabled: false
	})
	useEffect(() => {
		if(params?.categoryId){
			setCategoryId(Number(params?.categoryId))
			refetchCategories();
		}
	},[])
	useEffect(() => {
		if((user.authenticated || user.authenticated === false) && categoryId && inView){
			fetchNextPage()
		}
	},[user.authenticated,categoryId,inView])
	useEffect(() => {
		if(categories){
			const categoryData = categories.find(c => c.id === categoryId)
			setCategory(categoryData || null)
		}
	},[categories,categoryId])

	const playMusic = (data: {
		artist?: string
		song: string
		thumbnail: string
		url: string
	}) => {
		const playlistOrigin = [{
			artist: data?.artist,
			song: data.song,
			thumbnail: data.thumbnail,
			music_url: data.url,
		}]
		const playlistShuffle = [...playlistOrigin];
		dispatch({
			type: PLAYER_DATA,
			payload: {
				music_data: data,
				playlistOrigin,
				playlistShuffle
			}
		})
		dispatch({ type: SHOW_PLAYER })
		sendMusicPlay(data.url)
	}
	const sendMusicPlay = async (music_slug: string) => {
		await request('POST',`/webplayer/v1/music/play`,{
			"slug" : music_slug,
			"source": 1,
			"is_offline": true,
		})
	}

	const getMapContentBox = (list: SearchItem[]): ContentBoxType[] => {
		return list.map(l => {
			return {
				title: l.name,
				thumbnail: l.picture,
				subtitle: l.type !== 'artist'?l.caption:undefined,
				circle: l.type === 'artist',
				playOverlay: l.type !== 'artist',
				onClick: () => {
					if(!user.authenticated){
						dispatch({
							type: SHOW_MODAL_PREMIUM,
							payload: {
								thumbnail: l.picture
							}
						})
					}else{
						if(l.type === 'playlist'){
							history.push(`/playlist/${l.slug}`)
						}else if(l.type === 'artist'){
							history.push(`/artist/${l.slug}`)
						}else if(l.type === 'album'){
							history.push(`/album/${l.slug}`)
						}else if(l.type === 'song'){
							playMusic({
								artist: l.artist,
								song: l.name,
								thumbnail: l.picture,
								url: l.slug,
							})
						}
					}
				}
			}
		})
	}
	return <>
		<div className={'main-container'}>
			<h1 className={'page-title'}>{category?.name}</h1>
			<div className='timeline-list d-flex flex-column single'>
				<div className={'tl-items'}>
					<div className={'tl-content loskeun'}>
						{data?.pages.map((l: any,idx1) => (
							<React.Fragment key={`sc-more-pages-${idx1}`}>
								{getMapContentBox(l.data).map((l: any,idx2) => (
									<ContentBox
										thumbnail={l.thumbnail ?? undefined}
										title={l.title}
										circle={l.circle}
										subtitle={l.subtitle?l.subtitle:undefined}
										onClick={l.onClick?l.onClick:undefined}
										playOverlay={l.playOverlay} key={`cb-search-cat-${idx2}`} />
								))}
							</React.Fragment>
						))}
					</div>
				</div>
				<span ref={ref} className={'edge-infinite'}></span>
			</div>
		</div>
	</>
}

export default SearchCategory
