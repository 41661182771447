import './ModalPremium.scss'
import React, {useEffect, useState} from "react";
import {ModalPremiumType} from "../../typings/timeline";
import {UseAppDispatch} from "../../redux/store";
import {useSelector} from "react-redux";
import {InitialStateUIType} from "../../typings/state";
import {HIDE_MODAL_PREMIUM, SHOW_MODAL_LOGIN, UNGO_PREMIUM} from "../../redux/types";
import placeholder from "../../assets/images/playlist-placeholder.svg";
import {Modal} from "react-bootstrap";
import Button from "./UI/Button";
import {useHistory} from "react-router-dom";
interface IModalPremium{
	UI: InitialStateUIType;
}
const ModalPremium = () => {
	const history = useHistory();
	const dispatch = UseAppDispatch();
	const UISelector = useSelector((state: IModalPremium) => state.UI)
	const [showModalPremium, setShowModalPremium] = useState(false);
	const [modalPremium, setModalPremium] = useState<ModalPremiumType | null>(null);
	const handleClosePremium = () => {
		dispatch({ type: HIDE_MODAL_PREMIUM });
	}
	const handleShowPremium = () => setShowModalPremium(true);

	const handleSignUp = () => {
		handleClosePremium()
		dispatch({ type: SHOW_MODAL_LOGIN, payload: {
			showSignUp: true
		}})
	}

	const handleLogIn = () => {
		handleClosePremium()
		dispatch({ type: SHOW_MODAL_LOGIN, payload: {
			showSignUp: false
		}})
	}

	useEffect(()=>{
		if(UISelector.modalPremium){
			setModalPremium(UISelector.thumbnail ? { thumbnail: UISelector.thumbnail}: null)
			handleShowPremium()
		}else if(UISelector.modalPremium === false){
			setShowModalPremium(false)
		}
	},[UISelector.modalPremium, dispatch])
	return <>
		<Modal show={showModalPremium} onHide={handleClosePremium} className={'modal-premium'}>
			<Modal.Header closeButton closeVariant={'white'}></Modal.Header>
			<Modal.Body>
				<div className={'mb-premium d-flex justify-content-center align-items-center'}>
					<img className={'mb-premium-thumb'} src={modalPremium?.thumbnail ? modalPremium?.thumbnail : placeholder} />
					<div className={'mb-premium-content d-flex flex-column align-items-center'}>
						<div className={'mb-premium-content-title'}>
							Start Listening with a Free Account
						</div>
						<Button onClick={()=>handleSignUp()} primary className={'mb-3 w-181px'}>Sign Up</Button>
						<Button onClick={()=>handleLogIn()} primary className={'w-181px'}>Log In</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}
export default ModalPremium
