import placeholder from "../../assets/images/playlist-placeholder.svg";
import React from "react";
interface IHeaderContent {
	cover?: string
	covers?: string[]
	category: string
	title?: string
	info?: any[]
	user_name?: string
	user_avatar?: string
}
const HeaderContent = (props: IHeaderContent) => {
	let covers: any[] = []
	if(props.covers && props?.covers.length > 0){
		covers = props.covers;
		const coversLength = props.covers.length;
		const emptyLength = 4 - coversLength;
		if(emptyLength > 0){
			for(let it = 0;it<emptyLength;it++){
				covers.push(placeholder)
			}
		}
	}
	return <>
		<div className={'header-content'}>
			<div className={'hc-bg'}>
				{props?.cover && props?.cover !=="" && <img src={props?.cover} className={'single-bg'} />}
				{ props.covers && props?.covers.length > 0 ? (<div className={'multi-bg'}>
					{ covers.map((i, it) => (<img src={i} key={`multi-${it}`}/>)) }
				</div>) : null }
			</div>
			<div className={'d-flex align-items-end hc-container'}>
				<div className={'hc-cover-container'}>
					{props?.cover && props?.cover !=="" && <img className={'hc-cover'} src={props?.cover}/>}
					{ props.covers && props?.covers.length > 0 ? (<div className={'hc-cover-multi'}>
						{ props.covers.map((i, it) => (<img src={i} key={`multi-${it}`}/>)) }
					</div>) : null }
					{ (!props?.covers || props?.covers.length === 0) && (!props.cover || props?.cover === "") && <img className={'hc-cover'} src={placeholder}/>}
				</div>
				<div className={'d-flex flex-column hc-info'}>
					<span className={'hc-category'}>{props.category}</span>
					<h2 className={'hc-title'}>{props?.title}</h2>
					<div className={'d-flex align-items-center'}>
						{props.user_avatar && <img className={'hc-img-user me-3'} src={'https://placehold.co/24x24'}/>}
						<div className={'d-flex flex-column hc-user'}>
							{props.user_name && <span className={'hc-user-name'}>{props.user_name}</span>}
							{props.info && (<div className={'d-flex align-items-center'}>{props.info.map((i,it) => (
								<React.Fragment key={`it-info-${it}`}>
									<span className={'hc-info-2'}>{i}</span>
									{it !== (props.info?.length ?? 0) - 1 ? <i className={'fas fa-circle '}></i>: null}
								</React.Fragment>
							))}</div>)}
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default HeaderContent
