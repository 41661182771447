import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import React, {useState} from "react";
import {useInfiniteQuery, useQuery} from "react-query";
import {ApiDataResponse} from "../typings/api";
import {request} from "../services/api.service";
import './Home.scss'
import './Search.scss'
import ContentBox from "../components/pages/ContentBox";

interface IAlbumPurchased {
	"slug": string,
	"title": string,
	"artwork": string,
	"year": number,
	"member_id": number,
	"artist": string,
	"artist_id": number,
	"type": string,
	"is_favorited": boolean,
	"total_content": number
}

const Purchased: React.FC<RouteComponentProps> = (props) => {
	const history = useHistory();
	const [querySearch, setQuerySearch] = useState<string | null>(null)
	const { data } = useQuery<IAlbumPurchased[]>(['purchased-content-list',querySearch], async () => {
		try{
			const response = await request('GET',`/webplayer/v1/lib/purchased-content`,{
				q: querySearch,
			})
			return response?.data as IAlbumPurchased[]
		}catch (e) {
			return [] as IAlbumPurchased[]
		}
	})
	const onEnter = (value: string) => {
		setQuerySearch(value !=='' ? value : null)
	}
	return <>
		<div className={'main-container'}>
			<h1 className={'page-title text-capitalize'}>Purchased Content</h1>
			<div className={'search-form'}>
				<input type={'text'} className={'search-input'}
				       onKeyDown={event => {
					       if (event.key === 'Enter' || event.keyCode === 13) {
						       onEnter(event.currentTarget.value);
					       }
				       }}
				/>
				<i className={'fa fa-search'}></i>
			</div>
			<div className={'content-search'}>
				<div className='timeline-list d-flex flex-column single'>
					<div className={'tl-items'}>
						<div className={'tl-content loskeun'}>
							{data?.map((l: IAlbumPurchased,idx) => (
								<ContentBox
									thumbnail={l.artwork ?? undefined}
									title={l.title}
									onClick={()=>history.push(`/album/${l.slug}`)}
									circle={false} key={`cb-pur-cont-${idx}`} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default Purchased
