import React from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {request} from "../services/api.service";
import {PlaylistType} from "../typings/playlist";
import {secondToMinutes} from "../utils/common";
import HeaderContent from "../components/layout/HeaderContent";
import ContentList from "../components/pages/ContentList";
import './Playlist.scss'

const PersonalizedPlaylist: React.FC = () => {
	const params:any  = useParams();
	const { data: playlist, isLoading, refetch } = useQuery<PlaylistType | undefined>(['personalized-playlist',params?.slug], async () => {
		if(params?.slug){
			try{
				const response = await request('GET',`/webplayer/v1/lib/personalize-playlist/detail/${params?.slug}`)
				return response?.data as PlaylistType
			}catch (e) {
				return undefined
			}
		}else{
			return undefined
		}
	})
	const getInfo = () => {
		if(playlist){
			let infos = [`${playlist?.content.length} Songs`]
			const totalDuration = playlist.content.reduce((total, obj)=>{
				return total + obj.duration;
			}, 0)
			infos.push(`${secondToMinutes(totalDuration)} min`)
			return infos
		}
		return undefined
	}
	return <>
		<div className={'main-container-full'}>
			<HeaderContent category={'Playlist'} title={playlist?.title} covers={playlist?.covers} info={getInfo()} />
			<div className={'playlist-content'}>
				{playlist?.content &&  <ContentList content={playlist?.content} type={'playlist'}/>}
			</div>
		</div>
	</>
}

export default PersonalizedPlaylist
