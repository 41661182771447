import React from 'react';
import {BrowserRouter as Routers, Redirect, Route, RouteComponentProps, Switch, useRouteMatch} from "react-router-dom";
import {Home} from "../pages/Home";
import SideBar from "../components/layout/SideBar";
import SignUp from "../pages/SignUp";
import Header from "../components/layout/Header";
import ModalLogin from "../components/layout/ModalLogin";
import Search from "../pages/Search";
import Timeline from "../pages/Timeline";
import SearchCategory from "../pages/SearchCategory";
import SearchType from "../pages/SearchType";
import Library from "../pages/Library";
import Playlist from "../pages/Playlist";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import Artist from "../pages/Artist";
import Album from "../pages/Album";
import MusicPlayerWrapper from "../components/layout/MusicPlayerWrapper";
import ModalPremium from "../components/layout/ModalPremium";
import Purchased from "../pages/Purchased";
import PurchasedDetail from "../pages/PurchasedDetail";
import PersonalizedPlaylist from "../pages/PersonalizedPlaylist";
import NotFound from "../pages/NotFound";

interface IMainRoutesProps {
	component: any;
	path?: string;
	exact?: boolean;
	mustLogin?: boolean
}

const MainRoutes: React.FC<IMainRoutesProps> = (props) => {
	const { component: Component, ...rest } = props;
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)

	return <Route {...rest} render={matchProps =>
		(props.mustLogin && !user.authenticated)?(
			<Redirect to={'/'}/>
		):(
			<>
				<div className={'main-wrapper'}>
					<SideBar />
					<div className={'main-content'}>
						<Header />
						<Component {...matchProps} />
					</div>
					<ModalLogin />
					<ModalPremium />
				</div>
			</>
		)} />
}

const Routes: React.FC = () => {
	return (
		<Routers basename={'/'}>
			<Switch>
				<MainRoutes exact path={"/"} component={Home} />
				<MainRoutes exact path={"/search"} component={Search} />
				<MainRoutes exact path={"/search/category/:categoryId"} component={SearchCategory} />
				<MainRoutes exact path={"/search/by/:type"} component={SearchType} />
				<MainRoutes exact path={"/timeline/:category"} component={Timeline} />
				<MainRoutes exact path={"/library"} mustLogin={true} component={Library} />
				<MainRoutes exact path={"/playlist/:slug"} mustLogin={true} component={Playlist} />
				<MainRoutes exact path={"/artist/:slug"} mustLogin={true} component={Artist} />
				<MainRoutes exact path={"/album/:slug"} mustLogin={true} component={Album} />
				<MainRoutes exact path={"/purchased-content"} mustLogin={true} component={Purchased} />
				<MainRoutes exact path={"/purchased-content/:slug"} mustLogin={true} component={PurchasedDetail} />
				<MainRoutes exact path={"/personalized-playlist/:slug"} mustLogin={true} component={PersonalizedPlaylist} />
				<Route exact path={"/sign-up"} component={SignUp} />
				<MainRoutes path={"*"} component={NotFound} />
			</Switch>
		</Routers>
	)
}
export default Routes
