import React, {useEffect, useState} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import {VideoJsOptionType} from "../../typings/common";
import {getToken} from "../../utils/common";
const NUM_CHUNKS = 5;
const FIRST_CHUNK_SIZE = 5000;

export const VideoJS = (props: {
	options: VideoJsOptionType,
	onReady: any,
	duration: number
}) => {
	const [isFileLoaded, setIsFileLoaded] = useState(false);
	const [loadedBytes, setLoadedBytes] = useState(0);
	const [chunkSize, setChunkSize] = useState(FIRST_CHUNK_SIZE);
	const [contentSrc, setContentSrc] = useState<any>(null);
	const videoRef = React.useRef<any>(null);
	const playerRef = React.useRef(null);
	const {options, onReady, duration} = props;
	let sourceBuffer: any = null;
	let mediaSource: any = null;
	React.useEffect(() => {
		if (!playerRef.current) {
			const videoElement = document.createElement("video");
			videoElement.classList.add('vjs-big-play-centered');
			videoElement.setAttribute('playsinline', 'true')
			if(videoRef?.current){
				//@ts-ignore
				videoRef.current.appendChild(videoElement);
			}
			// const token = getToken();
			//@ts-ignore
			const player: any = playerRef.current = videojs(videoElement, {
				autoplay: options.autoplay,
				controls: options.controls,
				responsive: options.responsive,
				fluid: options.fluid,
			}, () => {
				videojs.log('player is ready', props.duration);
				onReady && onReady(player, props.duration);
			});
		} else {
			const player: any = playerRef.current;
			//@ts-ignore
			player.src(options.sources);
		}
		// if(videoRef.current && options.sources){
		// 	console.log(options.sources);
		// 	videoRef.current.play();
		// }
	}, [options, videoRef]);
	// @ts-ignore
	// useEffect(() => {
	// 	setMediaSource();
	//
	// 	return () => {
	// 		removeEventListeners();
	// 	};
	// }, [options.sources, videoRef]);
	// useEffect(() => {
	// 	const player: any = playerRef.current;
	// 	if(player && contentSrc){
	// 		player.src([
	// 			{
	// 				src: contentSrc,
	// 				type: 'audio/mpeg'
	// 			}
	// 		]);
	// 		return () => {
	// 			removeEventListeners();
	// 		};
	// 	}
	// }, [contentSrc,playerRef.current]);
	const setMediaSource = () => {
		// console.log('yow')
		mediaSource = new MediaSource();
		mediaSource.addEventListener('sourceopen', sourceOpen);

		setContentSrc(URL.createObjectURL(mediaSource));
	};

	const removeEventListeners = () => {
		mediaSource && mediaSource.removeEventListener('sourceopen', sourceOpen);
		sourceBuffer && sourceBuffer.removeEventListener('updateend', appendBuffer);
	};

	const sourceOpen = () => {
		// console.log('ependdd')
		sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
		appendBuffer();
		sourceBuffer.addEventListener('updateend', appendBuffer);
		const player: any = playerRef.current;
		player.play();
	};

	const appendBuffer = () => {
		const start = loadedBytes ? loadedBytes + 1 : loadedBytes;
		const end = loadedBytes ? loadedBytes + chunkSize : chunkSize;
		// console.log(start,end)
		if (!isFileLoaded) {
			// @ts-ignore
			rangeContent(options.sources[0].src, start, end)
				.then(({data, contentRange}) => {
					sourceBuffer.appendBuffer(data);
					setLoadedBytes(end);
					setIsFileLoaded(data.byteLength < chunkSize);

					if (chunkSize === FIRST_CHUNK_SIZE) {
						// Content-Range example: bytes 200-1000/67589
						// @ts-ignore
						setChunkSize(Math.ceil(contentRange?.substring(contentRange?.indexOf('/') + 1) / NUM_CHUNKS));
					}
				})
				.catch((err) => console.log(err));
		} else {
			removeEventListeners();
			mediaSource.endOfStream();
		}
	};

	const rangeContent = (url = options.sources[0].src, start = 0, end: any = null) => {
		const token = getToken();
		const authHeaderValue = `Bearer ${token}`;

		const fetchSettings = {
			headers: new Headers({
				Authorization: authHeaderValue,
				Range: `bytes=${start}-${end ? end : ''}`
			})
		};

		const fetchMethod = fetch(url, fetchSettings);
		const data = fetchMethod.then((res) => res.arrayBuffer());
		const header = fetchMethod.then((res) => res.headers.get('Content-Range'));

		return Promise.all([data, header]).then(([data, contentRange]) => ({
			data,
			contentRange
		}));
	};

	const playChunk = async () => {
		const player: any = playerRef.current;
		const token = getToken();
		const myMediaSource = new MediaSource();
		const url = URL.createObjectURL(myMediaSource);
		player.src([
			{
				src: url,
				type: 'audio/mp4'
			}
		]);
		const vidBlob = await (await fetch(options.sources[0].src.replace('-public',''), {
			method: "GET", // *GET, POST, PUT, DELETE, etc.
			headers: {
				// "Content-Type": "audio/mp3",
				"Authorization":`Bearer ${token}`
			}
		})).blob()
		const vidBuff = await vidBlob.arrayBuffer();
		const sourceBuffer: any = await new Promise((resolve, reject) => {
			const getSourceBuffer = () => {
				try {
					const sourceBuffer = myMediaSource.addSourceBuffer('audio/mpeg');
					resolve(sourceBuffer);
				} catch (e) {
					reject(e);
				}
			};
			if (myMediaSource.readyState === 'open') {
				getSourceBuffer();
			} else {
				myMediaSource.addEventListener('sourceopen', getSourceBuffer);
			}
		});

		// Now that we have an "open" source buffer, we can append to it
		sourceBuffer.appendBuffer(vidBuff);
		// Listen for when append has been accepted and
		// You could alternative use `.addEventListener` here instead
		sourceBuffer.onupdateend = () => {
			// Nothing else to load
			myMediaSource.endOfStream();
			// Start playback!
			// Note: this will fail if video is not muted, due to rules about
			// autoplay and non-muted videos
			player.play();
		};
		console.log({
			sourceBuffer,
			myMediaSource,
		});
	}

	React.useEffect(()=>{
		if(playerRef.current && options.sources.length>0){
			const player: any = playerRef.current;
			// playChunk()
			player.src(options.sources);
			// console.log('lalalala')
			player.play();
		}
	},[options.sources,playerRef])

	// Dispose the Video.js player when the functional component unmounts
	React.useEffect(() => {
		const player: any = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	return (
		<div data-vjs-player=""  style={{display:'none'}} >
			<div ref={videoRef} />
		</div>
	);
}

export default VideoJS;
