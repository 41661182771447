import './Artist.scss'
import React, {useEffect, useState} from "react";
import {RouteComponentProps, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {useQuery} from "react-query";
import {request} from "../services/api.service";
import {ArtistAlbumType, ArtistType} from "../typings/artist";
import HeaderArtist from "../components/layout/HeaderArtist";
import Button from "../components/layout/UI/Button";
import shareIcon from "../assets/images/icon/share-icon.svg";
import ContentBox from "../components/pages/ContentBox";
import {copyValue} from "../utils/common";
import {toast} from "react-toastify";

const Artist: React.FC<RouteComponentProps> = (props) => {
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const params:any  = useParams();
	const [slug, setSlug] = useState<string | null>(null);
	const [isFollow, setIsFollow] = useState<boolean>(false);
	const { data: artist, isLoading,refetch } = useQuery<ArtistType | undefined, boolean>(['artist-detail',slug], async () => {
		if(slug){
			try{
				const response = await request('GET',`/webplayer/v1/artist${user.authenticated?'-member':''}/detail/${slug}`)
				return response?.data as ArtistType
			}catch (e) {
				return undefined
			}
		}else{
			return undefined
		}
	})
	useEffect(() => {
		if(params?.slug){
			setSlug(decodeURI(params?.slug))
		}
	},[])
	useEffect(()=>{
		if(artist){
			setIsFollow(artist.is_followed)
		}
		console.log('artist',artist)
	},[artist])
	const artistFollow = async (state: 'follow' | 'unfollow') => {
		const res = await request('POST',`/webplayer/v1/artist-member/${state}`,{
			artist_slug: artist?.slug
		})
		setIsFollow(state === 'follow')
	}
	const goToAlbum = (slug: string) => {
		props.history.push(`/album/${slug}`)
	}

	const share = () => {
		if(artist){
			copyValue(artist.share_url);
			toast.success("Link copied to clipboard!", {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
				hideProgressBar: false,
				pauseOnHover: false,
			});
		}
	}

	return <>
		<div className={'main-container-full'}>
			<HeaderArtist cover={artist?.avatar} name={artist?.name} bio={artist?.bio}/>
			<div className={'artist-content'}>
				<div className={'ac-setting'}>
					{ !isFollow ?
						<Button white className={'btn-follow'} onClick={()=>artistFollow('follow')}>Follow</Button> :
						<Button primary className={'btn-follow'} onClick={()=>artistFollow('unfollow')}>Following</Button>
					}
					<img className={'ac-setting-icon cursor-pointer'} src={shareIcon} onClick={share} />
				</div>
				<div className={'ac-discography'}>
					<div className={'ac-disco-title'}>Discography</div>
					<div className='timeline-list d-flex flex-column single'>
						<>
							{
								<div className={'tl-items'}>
									<div className={'tl-content loskeun single'}>
										{artist?.content.map((l: ArtistAlbumType ,idx2) => (
											<ContentBox
												thumbnail={l.artwork ?? undefined}
												title={l.title}
												subtitle={`Album - ${l.year}`}
												onClick={()=>goToAlbum(l.slug)}
											/>
										))}
									</div>
								</div>
							}
						</>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default Artist
