import React, {useEffect, useState} from "react";
import logo from '../../assets/images/logo/logo-full-white.png';
import {ReactComponent as IconHome} from '../../assets/images/icon/home-icon.svg';
import {ReactComponent as IconSearch} from '../../assets/images/icon/search-icon.svg';
import {ReactComponent as IconLibrary} from '../../assets/images/icon/library-icon.svg';
import './SideBar.scss'
import {useHistory, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../../typings/state";
type MenuNavType = {
	label: string
	slug: string,
	Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	link: string
	show: boolean
}
const MenuNav: MenuNavType[] = [
	{
		label: 'Home',
		slug: 'home',
		Icon: IconHome,
		link:'/',
		show: true
	},
	{
		label: 'Search',
		slug: 'search',
		Icon: IconSearch,
		link:'/search',
		show: true
	},
	{
		label: 'Library',
		slug: 'library',
		Icon: IconLibrary,
		link:'/library',
		show: false
	},
]
const SideBar: React.FC = () => {
	const {path} = useRouteMatch()
	const history = useHistory()
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	let currentMenu = 'home'
	switch (path){
		case '/search':
			currentMenu = 'search'
			break;
		case '/library':
			currentMenu = 'library'
			break;
	}
	const [activeNav, setActiveNav] = useState<string>(currentMenu)
	const [showLibrary, setShowLibrary] = useState<boolean>(false)
	const [menuNav, setMenuNav] = useState<MenuNavType[]>(MenuNav.filter(m => m.show))
	useEffect(() => {
		if(user.authenticated){
			const menu = MenuNav
			menu[2].show = true;
			setMenuNav(menu)
		}
	},[user.authenticated])
	const onClickNav = (m: MenuNavType) => {
		setActiveNav(m.slug)
		history.push(m.link)
	}
	return (
		<div className={'sidebar'}>
			<div className={'logo'}>
				<img src={logo} alt={'logo'}/>
			</div>
			<div className={'sidebar-nav'}>
				<div className={'sidebar-nav-title'}>Browse music</div>
				<ul className={'sb-nav-list'}>
					{
						menuNav.map((m, idx) => (
							<li className={activeNav===m.slug?'active':''} onClick={()=>onClickNav(m)} key={idx}>
								<div className={'icon'}>
									<m.Icon />
								</div>
								<span className={'nav-label'}>{m.label}</span>
							</li>
						))
					}
				</ul>
			</div>
		</div>
	)
}
export default SideBar
