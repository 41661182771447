import {RouteComponentProps, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {ArtistAlbumType} from "../typings/artist";
import {request} from "../services/api.service";

const PurchasedDetail: React.FC<RouteComponentProps> = (props) => {
	const params:any  = useParams();
	// const [slug, setSlug] = useState<string | null>(null);
	const { data: album, isLoading, refetch } = useQuery<any>(['purchased-content-detail',params?.slug], async () => {
		if(params?.slug){
			try{
				const response = await request('GET',`/webplayer/v1/lib/detail-purchased-album/${params?.slug}`)
				console.log(response?.data)
				return response?.data as any
			}catch (e) {
				return undefined
			}
		}else{
			return undefined
		}
	})
	return <></>
}

export default PurchasedDetail
