import './MusicPlayer.scss'
import pauseIcon from '../../assets/images/icon/pause-white-icon.png'
import playIcon from '../../assets/images/icon/play-white-icon.png'
import {useEffect, useRef, useState} from "react";
import MusicSlider from "./MusicSlider";
import {useSelector} from "react-redux";
import {InitialStatePlayer, InitialStateUserType} from "../../typings/state";
import {VideoJsOptionType} from "../../typings/common";
import VideoJS from "./VideoJs";
import {getBaseUrl, request} from "../../services/api.service";
import {secondsToMinutesAndSeconds, shuffle} from "../../utils/common";
import {UseAppDispatch} from "../../redux/store";
import {PLAYER_DATA, PLAYER_ENDED} from "../../redux/types";
import {useRouteMatch} from "react-router-dom";
import {sendPlayTrack} from "../../services/song.service";
// import ShakaPlayerWrapper from "./ShakaPlayerWrapper";
const MusicPlayerWrapper = () => {
	const dispatch = UseAppDispatch();
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const playerRef = useRef(null);
	const playerState = useSelector((state: {player: InitialStatePlayer}) => state.player)
	const durationRef = useRef<number | null>(playerState?.music_data?.duration || 0);
	const [showPlayer, setShowPlayer] = useState<boolean>(false)
	const [durationTextRunning, setDurationTextRunning] = useState<string>('0:00')
	const [durationText, setDurationText] = useState<string>('0:00')
	const [musicData, setMusicData] = useState<{
		artist: string
		song: string
		thumbnail: string
		url: string
		duration?: number,
		t?: string,
	}>({
		artist: '',
		song: '',
		thumbnail: '',
		url: '',
		duration: 0,
	})
	const [videoJsOptions, setVideoJsOptions] = useState<VideoJsOptionType>({
		autoplay: false,
		controls: true,
		responsive: false,
		fluid: false,
		sources: []
	})
	const [isPlay, setIsPlay] = useState<boolean>(false)
	const [isShuffled, setIsShuffled] = useState<boolean>(false)
	const [isMuted, setIsMuted] = useState<boolean>(false)
	const [canPrev, setCanPrev] = useState<boolean>(false)
	const [canNext, setCanNext] = useState<boolean>(false)
	const [duration, setDuration] = useState<number>(playerState?.music_data?.duration || 0)
	const handleShuffle = () => {
		if(user?.profile?.type !== 'Free') {
			setIsShuffled(!isShuffled)
		}
	}

	const [hoverDuration, setHoverDuration] = useState(false)
	const [percent, setPercent] = useState(0)
	const [percentVolume, setPercentVolume] = useState(100)
	const getMusicUrl = async (slug: string) => {
		const response = await request('GET',`/webplayer/v1/music/get-url-multiple/${slug}`,{
			"type": "stream"
		})
		if(response?.data){
			const musicUrl: any = response?.data[0];
			durationRef.current = Number(musicUrl.duration / 1000);
			setVideoJsOptions({...videoJsOptions, sources:[
					{
						src: musicUrl.url,
						type: 'audio/mp3'
					}
			]})
		}
	}


	useEffect(()=>{
		if(playerState.show){
			setShowPlayer(true)
			if(playerRef.current) {
				const player: any = playerRef.current;
				player.play()
				player.pause();
			}
		}
		if(playerState.music_data && playerState.playlistShuffle && playerState.playlistOrigin){
			setMusicData(playerState.music_data)
			const playlists = isShuffled ? playerState.playlistShuffle : playerState.playlistOrigin;
			const currentMusic = playlists.find((p)=>{
				return p.music_url === playerState?.music_data?.url
			});
			if(currentMusic){
					getMusicUrl(currentMusic.music_url);
			}
		}
		handlePrevNext()
	},[playerState.show,playerState.music_data,playerState.playlistShuffle,playerState.playlistOrigin])
	useEffect(()=>{
		handlePrevNext()
	},[isShuffled])
	useEffect(()=>{
		if(playerState.ended){
			handleEnded()
		}
	},[playerState.ended])
	useEffect(()=>{
		if(playerState?.music_data){
			const dur = playerState?.music_data?.duration || 0
			if(dur > 0){
				setDuration(dur)
			}
		}
	},[playerState.music_data,duration])
	useEffect(() => {
		if(duration > 0){
			setDurationText(secondsToMinutesAndSeconds(duration))
		}
	},[duration])
	useEffect(() => {
		if(user.profile?.type){
			if(user.profile?.type === 'Free'){
				setIsShuffled(true);
			}
		}
	},[user.profile?.type])

	const handlePrevNext = () => {
		if(playerState.playlistShuffle && playerState.playlistOrigin){
			const playlistLength = playerState.playlistOrigin.length;
			if(playlistLength > 1){
				const playlists = isShuffled ? playerState.playlistShuffle : playerState.playlistOrigin;
				const currentMusicIndex = playlists.findIndex(p => {
					return p.music_url === playerState?.music_data?.url
				})
				if(currentMusicIndex > -1){
					if(currentMusicIndex === 0){
						setCanPrev(false)
						setCanNext(true)
					}else if(currentMusicIndex === playlistLength -1){
						setCanPrev(true)
						setCanNext(false)
					}else{
						setCanPrev(true)
						setCanNext(true)
					}
				}
			}else{
				setCanPrev(false)
				setCanNext(false)
			}
		}
	}

	const handleEnded = () => {
		if(playerState?.playlistOrigin.length > 1){
			const playlists = isShuffled ? playerState.playlistShuffle : playerState.playlistOrigin;
			const currentMusicIndex = playlists.findIndex(p => {
				return p.music_url === playerState?.music_data?.url
			})
			if(currentMusicIndex < playlists.length - 1){
				nextPlay()
			}else{
				const player: any = playerRef.current;
				player.pause()

			}
		}
	}

	const handlePlayerReady = (player: any, durationPlayer: number) => {
		playerRef.current = player;
		player.on('waiting', () => {
			// videojs.log('player is waiting');
		});
		player.on('playing', () => {
			// console.log('player is playing');
			setIsPlay(true)
		});
		player.on('ended', () => {
			setIsPlay(false)
			dispatch({
				type: PLAYER_ENDED
			})
		});
		player.on('loadedmetadata', () => {
			// console.log('player is loadedmetadata');
			if(player.duration() !== Infinity){
				setDuration(player.duration())
			}else{
				const dur = durationRef.current || 0;
				setDuration(dur)
				setDurationText(secondsToMinutesAndSeconds(dur))
			}
		});
		player.on('timeupdate', () => {
			// console.log('player is timeupdate');
			let dur = Number(duration);
			if(player.duration()  !== Infinity ){
				dur = Number(player.duration())
			}else{
				dur = durationRef.current || 0;
			}
			const curr = Number(player.currentTime());
			const percent = ((curr / dur) * 100)
			// console.log(dur, curr, percent)
			setPercent(percent)
			setDurationTextRunning(secondsToMinutesAndSeconds(Math.round(curr)))
		});
		player.on('dispose', () => {
			// videojs.log('player will dispose');
		});
	};

	const handlePlay = () => {
		if(playerRef.current){
			const player: any = playerRef.current;
			if(isPlay){
				player.pause();
			}else{
				player.play();
			}
			setIsPlay(!isPlay)
		}
	}

	const handleSeek = (percent: number) => {
		setPercent(percent)

		if(duration!==0 && playerRef.current){
			const progress = percent/100;
			// console.log(duration,progress,'ssss')
			const player: any = playerRef.current
			player.currentTime(duration*progress)
		}
	}

	const setVolume = (volume: number) => {
		if(playerRef.current){
			const player: any = playerRef.current;
			player.volume(volume/100);
			setPercentVolume(volume)
		}
	}

	const setMuted = () => {
		if(playerRef.current){
			const player: any = playerRef.current;
			player.muted(!isMuted);
			setIsMuted(!isMuted)
		}
	}

	const nextPlay = () => {
		if(canNext){
			const playlists = isShuffled ? playerState.playlistShuffle : playerState.playlistOrigin;
			const currentMusicIndex = playlists.findIndex(p => {
				return p.music_url === playerState?.music_data?.url
			})
			const songData = playlists[currentMusicIndex+1]
			dispatch({
				type: PLAYER_DATA,
				payload: {
					music_data: {
						artist: songData?.artist,
						song: songData.song,
						thumbnail: songData.thumbnail,
						url: songData.music_url,
						duration: songData?.duration ?? 0,
						t: songData?.t,
					},
					playlistShuffle: playerState.playlistShuffle,
					playlistOrigin: playerState.playlistOrigin,
					playlist_slug: playerState?.playlist_slug || '',
					source: playerState.source
				}
			})
			if(playerState.source === 'playlist' && playerState?.playlist_slug !== ''){
				sendPlayTrack(songData.music_url, playerState.source, playerState.playlist_slug)
			}else{
				sendPlayTrack(songData.music_url, playerState.source !== 'album' ? 'single' : playerState.source)
			}
		}
	}

	const prevPlay = () => {
		if(canPrev){
			const playlists = isShuffled ? playerState.playlistShuffle : playerState.playlistOrigin;
			const currentMusicIndex = playlists.findIndex(p => {
				return p.music_url === playerState?.music_data?.url
			})
			const songData = playlists[currentMusicIndex-1]
			dispatch({
				type: PLAYER_DATA,
				payload: {
					music_data: {
						artist: songData?.artist,
						song: songData.song,
						thumbnail: songData.thumbnail,
						url: songData.music_url,
						duration: songData?.duration ?? 0,
						t: songData?.t,
					},
					playlistShuffle: playerState.playlistShuffle,
					playlistOrigin: playerState.playlistOrigin,
					playlist_slug: playerState?.playlist_slug || '',
					source: playerState.source
				}
			})
			if(playerState.source === 'playlist' && playerState?.playlist_slug !== ''){
				sendPlayTrack(songData.music_url, playerState.source, playerState.playlist_slug)
			}else{
				sendPlayTrack(songData.music_url, playerState.source !== 'album' ? 'single' : playerState.source)
			}
		}
	}

	return <>
		{(
			<div className={'music-player-wrapper'} style={{
				display : showPlayer ? 'block' : 'none'
			}}>
				<div className={'d-flex justify-content-between mpw-content'}>
					<div className={'mpw-artist'}>
						<img src={musicData.thumbnail} className={'mpw-artist-img'}/>
						<div className={'mpw-artist-info'}>
							<div className={'mpw-song-name'}>{musicData.song}</div>
							<span className={'mpw-artist-name'}>{musicData.artist}</span>
						</div>
					</div>
					<div className={'mpw-player'}>
						<VideoJS options={videoJsOptions} onReady={handlePlayerReady} duration={duration} />
						<div className={'mpw-player-control'}>
							<div className={`mpwp-control-shuffle ${isShuffled?'shuffled':''}`} onClick={handleShuffle}>
								<i className={'fa fa-random'}></i>
							</div>
							<div className={`mpwp-control-nav ${!canPrev ? 'disabled':''}`} onClick={prevPlay}>
								<i className={'fa fa-step-backward'}></i>
							</div>
							<div className={'mpwp-control-play'} onClick={handlePlay}>
								<img src={isPlay ? pauseIcon : playIcon} />
							</div>
							<div className={`mpwp-control-nav ${!canNext ? 'disabled':''}`} onClick={nextPlay}>
								<i className={'fa fa-step-forward'}></i>
							</div>
						</div>
						<div className={'mpw-player-duration'}>
							<span>{durationTextRunning}</span>
							<MusicSlider
								defaultValue={0}
								value={percent}
								width={610}
								height={6}
								type={'range'}
								size={0}
								onMouseOver={() => setHoverDuration(true)}
								onMouseLeave={() => setHoverDuration(false)}
								onChange={(event)=>handleSeek(Number(event.target.value))}
								highlighted={'#70CFED'} colorBefore={'#70CFED'} colorAfter={'#87798D'}
								percent={percent} growTo={15} seeking={hoverDuration}
								disabled={user?.profile?.type === 'Free'}
							/>
							<span>{durationText}</span>
						</div>
					</div>
					<div className={'mpw-volume'}>
						<div className={'mpwv-mute cursor-pointer'} onClick={()=>setMuted()}>
							<i className={`fa fa-volume-${isMuted? 'mute':'up'}`}></i>
						</div>
						<MusicSlider
							defaultValue={percentVolume}
							width={139}
							height={3}
							type={'range'}
							size={0}
							onChange={(event)=>setVolume(Number(event.target.value))}
							highlighted={'#ffffff'} colorBefore={'#ffffff'} colorAfter={'#87798D'}
							percent={percentVolume} growTo={10} seeking={true}
						/>
					</div>
				</div>
			</div>
		)}
	</>
}
export default MusicPlayerWrapper
