import React, {useEffect, useState} from "react";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {UseAppDispatch} from "../redux/store";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../typings/state";
import {useInfiniteQuery} from "react-query";
import {ContentBoxType, ModalPremiumType} from "../typings/timeline";
import {request} from "../services/api.service";
import {PLAYER_DATA, SHOW_MODAL_LOGIN, SHOW_MODAL_PREMIUM, SHOW_PLAYER} from "../redux/types";
import './Home.scss'
import ContentBox from "../components/pages/ContentBox";
import { useInView } from 'react-intersection-observer'
import {ApiDataResponse} from "../typings/api";
import {sendPlayTrack} from "../services/song.service";

const Timeline: React.FC<RouteComponentProps> = (props) => {
	const { ref, inView } = useInView()
	const dispatch = UseAppDispatch();
	const history = useHistory();
	const params:any  = useParams();
	const [category, setCategory] = useState<string | null>(null);
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const { data: timelineList, isFetching,
		isFetchingNextPage,
		fetchNextPage} = useInfiniteQuery(['timeline-more',category], async ({ pageParam = 1 }) => {
		if(category){
			try{
				const response = await request('GET',`/webplayer/v1/home${user.authenticated?'-member':''}/segment-more`,{
					page:pageParam,
					category: category
				})
				return response as ApiDataResponse
			}catch (e) {
				return []
			}
		}else{
			return []
		}
	},{
		getPreviousPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		getNextPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		enabled: false
	})
	useEffect(() => {
		if(params?.category){
			setCategory(decodeURI(params?.category))
		}
	},[])
	useEffect(() => {
		if((user.authenticated || user.authenticated === false) && category && inView){
			fetchNextPage()
		}
	},[user.authenticated,category,inView])

	const playMusic = (data: {
		artist?: string
		song: string
		thumbnail: string
		url: string
	}) => {
		const playlistOrigin = [{
			artist: data?.artist,
			song: data.song,
			thumbnail: data.thumbnail,
			music_url: data.url,
		}]
		const playlistShuffle = [...playlistOrigin];
		dispatch({
			type: PLAYER_DATA,
			payload: {
				music_data: data,
				playlistOrigin,
				playlistShuffle
			}
		})
		dispatch({ type: SHOW_PLAYER })
		sendPlayTrack(data.url, 'single')
	}

	const getMapContentBox = (list: any[], category:string | null): ContentBoxType[] => {
		switch (category) {
			case 'For You':
				return list.map(l => {
					return {
						title: l.title,
						covers: l.covers,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/personalized-playlist/${l.slug}`)
							}
						}
					}
				})
			case 'Recommended Playlist':
				return list.map(l => {
					return {
						title: l.title,
						thumbnail: l.thumbnail,
						subtitle: 'playlist',
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/playlist/${l.slug}`)
							}
						}
					}
				})
			case 'Recently Played':
				return list.map(l => {
					let content: any = {
						thumbnail: l.type === 'playlist'? l.cover: l.thumbnail,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'playlist'){
									history.push(`/playlist/${l.slug}`)
								}else if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}else if(l.type === 'song'){
									playMusic({
										song: l.title,
										thumbnail: l.thumbnail,
										url: l.slug,
									})
								}
							}
						}
					}
					switch(l.type){
						case 'playlist':
							content['title'] = l.title
							content['subtitle'] = 'playlist'
							break;
						case 'song':
							content['title'] = l.title
							content['subtitle'] = l.caption
							break;
						case 'album':
							content['title'] = l.name
							content['subtitle'] = l.caption
							break;
					}
					return content
				})
			case 'New releases':
				return list.map(l => {
					return {
						title: l.artist,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}else if(l.type === 'song'){
									playMusic({
										artist: l.artist,
										song: l.name,
										thumbnail: l.thumbnail,
										url: l.slug,
									})
								}
							}
						}
					}
				})
			case 'Artists Store':
				return list.map(l => {
					return {
						title: l.name,
						thumbnail: l.thumbnail,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/artist/${l.slug}`)
							}
						}
					}
				})
			case 'Popular Artists':
				return list.map(l => {
					return {
						title: l.name,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								history.push(`/artist/${l.slug}`)
							}
						}
					}
				})
			case 'Popular Albums':
				return list.map(l => {
					return {
						title: l.name,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}
							}
						}
					}
				})
			case 'Trending':
				return list.map(l => {
					return {
						title: l.title,
						thumbnail: l.thumbnail,
						subtitle: l.caption,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}else if(l.type === 'song'){
									playMusic({
										artist: l.artist,
										song: l.title,
										thumbnail: l.thumbnail,
										url: l.slug,
									})
								}
							}
						}
					}
				})
			default:
				return list.map(l => {
					let content: any = {
						thumbnail: l.thumbnail,
						onClick: () => {
							if(!user.authenticated){
								dispatch({
									type: SHOW_MODAL_PREMIUM,
									payload: {
										thumbnail: l.thumbnail
									}
								})
							}else{
								if(l.type === 'playlist'){
									history.push(`/playlist/${l.slug}`)
								}else if(l.type === 'artist'){
									history.push(`/artist/${l.slug}`)
								}else if(l.type === 'album'){
									history.push(`/album/${l.slug}`)
								}else if(l.type === 'song'){
									playMusic({
										artist: l.artist,
										song: l.title,
										thumbnail: l.thumbnail,
										url: l.slug,
									})
								}
							}
						}
					}
					switch(l.type){
						case 'playlist':
							content['title'] = l.title
							content['subtitle'] = 'playlist'
							break;
						case 'song':
							content['title'] = l.title
							content['subtitle'] = l.caption
							break;
						case 'album':
							content['title'] = l.name
							content['subtitle'] = l.caption
							break;
						case 'artist':
							content['title'] = l.name
							content['subtitle'] = l.caption
							break;
					}
					return content
				})
		}
		return []
	}
	return <>
		<div className={'main-container'}>
			<h1 className={'page-title'}>{category}</h1>
			<div className='timeline-list d-flex flex-column single'>
				<>
					{
						<div className={'tl-items'}>
							<div className={'tl-content loskeun single'}>
								{timelineList?.pages.map((l: any,idx1) => (
									<React.Fragment key={`tm-more-pages-${idx1}`}>
										{getMapContentBox(l.data, category).map((l: any,idx2) => (
											<ContentBox
												thumbnail={l.thumbnail ?? undefined}
												covers={l.covers ?? undefined}
												title={l.title}
												subtitle={l.subtitle?l.subtitle:undefined}
												onClick={l.onClick?l.onClick:undefined}
												playOverlay={true} key={`cb-timeline-${idx2}`} />
										))}
									</React.Fragment>
								))}
							</div>
						</div>
					}
				</>
				<span ref={ref} className={'edge-infinite'}></span>
			</div>
		</div>
	</>
}

export default Timeline
