import {Modal} from "react-bootstrap";
import Button from "./UI/Button";
import React, {useEffect, useState} from "react";
import { useForm } from 'react-hook-form'
import {UseAppDispatch} from "../../redux/store";
import {InitialStateUIType} from "../../typings/state";
import {firebaseConfig} from '../../utils/constant';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, signOut, updateProfile} from "firebase/auth";
import {useSelector} from "react-redux";
import {HIDE_MODAL_LOGIN, LOADING_UI, SET_ERRORS, UNGO_PREMIUM, UNLOADING_UI} from "../../redux/types";
import './ModalLogin.scss'
import logoWhite from '../../assets/images/logo/logo-white.png';
import fbIcon from '../../assets/images/icon/fb-icon.svg';
import googleIcon from '../../assets/images/icon/google-icon.svg';
import appleIcon from '../../assets/images/icon/apple-icon.svg';
import Input from "./UI/Input";
import {login, loginSocial} from "../../services/user.service";
import {useHistory} from "react-router-dom";
import {LoginSocialType} from "../../typings/user";
import useQueryParams from "../../hooks/useQueryParams";
import {getToken} from "../../utils/common";
interface IModalLogin{
	UI: InitialStateUIType;
}
const ModalLogin: React.FC = () => {
	const queryParams = useQueryParams();
	const history = useHistory();
	const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm();
	const dispatch = UseAppDispatch();
	const modalLogin = useSelector((state: IModalLogin) => state.UI)
	const [show, setShow] = useState(false)
	const [showLogin, setShowLogin] = useState(false)
	const [errorLogin, setErrorLogin] = useState<{error: boolean,msg: string}>({error: false, msg:''})
	const handleClose = () => {
		dispatch({ type: HIDE_MODAL_LOGIN })
		dispatch({
			type: UNGO_PREMIUM,
		})
	};
	const handleShow = () => setShow(true);
	useEffect(()=>{
		if(modalLogin.modalLogin){
			const app = initializeApp(firebaseConfig);
			handleShow()
			setShowLogin(!modalLogin.showSignUp);
		}else if(modalLogin.modalLogin === false){
			setShowLogin(false)
			setShow(false)
			setErrorLogin({error:false,msg: ''})
			reset()
		}
	},[modalLogin.modalLogin, dispatch])

	const Socials = () => {
		return <>
			<div className={'social'}>
				<div className={'social-item'} onClick={()=>googleSignIn('register')}>
					<img src={fbIcon} className={'logo'} />
				</div>
				<div className={'social-item'} onClick={()=>facebookSignIn('register')}>
					<img src={googleIcon} className={'logo'} />
				</div>
				<div className={'social-item'} onClick={()=>appleSignIn('register')}>
					<img src={appleIcon} className={'logo'} />
				</div>
			</div>
		</>
	}

	const ShowMessageErrorLogin = () => {
		if(errorLogin.error){
			return <>
				<div className={'alert alert-danger'}>
					<p>{errorLogin.msg}</p>
				</div>
			</>
		}
		return <></>
	}

	const ViewLogin = () => {
		return (<>
			<div className={'ml-title title-login'}>
				Log In
			</div>
			<form className={'form-wrapper'} onSubmit={handleSubmit(onSubmit)}>
				<div className={'form-group mb-3'}>
					<Input placeholder={'Email'} Icon={'fa fa-envelope'}
					       name='email'
					       register={register}
					       errors={errors}
					       rules={{
						       required: "Email is required",
					       }}
					/>
				</div>
				<div className={'form-group mb-2'}>
					<Input placeholder={'Password'} Icon={'fa fa-lock'}
					       name='password'
					       type='password'
					       register={register}
					       errors={errors}
					       rules={{
						       required: "Password is required",
					       }}
					/>
				</div>
				<ShowMessageErrorLogin />
				<div className={'d-flex justify-content-end mb-5'}>
					<div className={'forgot-pass'}>Forgot Password?</div>
				</div>
				<Button primary className={'w-100 pe-0 ps-0'}>Login</Button>
			</form>
			<div className={'or'}>
				<span>Or Login With</span>
			</div>
			<Socials />
		</>)
	}

	const goSignUp = () => {
		history.push('/sign-up')
	}
	const ViewSignUp = () => {
		return (<>
			<img src={logoWhite} className={'logo'} />
			<div className={'ml-title'}>
				Sign Up
			</div>
			<Socials />
			<div className={'or'}>
				<span>Or</span>
			</div>
			<Button primary className={'btn-sign-up-email'} onClick={goSignUp}>
				<div className={'d-flex align-items-center'}>
					<i className={'fa fa-envelope me-3'}></i>
					<span>Sign Up with Email</span>
				</div>
			</Button>
			<p className={'have-account-text'}>Already have account?</p>
			<Button primary className={'btn-login'} onClick={() => setShowLogin(true)}>
				Log In
			</Button>
		</>)
	}
	const onSubmit = async (data: any) => {
		try{
			await login(data, dispatch)
			if(modalLogin.goPremium){
				const token = getToken();
				document.location.href = (process.env.REACT_APP_ACCOUNT_URL || '')+`/#/login?_t=${token}&type=subscription`;
			}else{
				window.location.replace(queryParams.get('red') || '/')
			}
			setErrorLogin({error:false,msg: ''})
		}catch (e: Error | any) {
			dispatch({ type: UNLOADING_UI })
			setErrorLogin({error:true,msg: e.message})
		}

	}

	const googleSignIn = (type: 'login'|'register') => {
		dispatch({
			type:LOADING_UI
		});
		const provider = new GoogleAuthProvider();
		provider.addScope('email,profile');
		const auth = getAuth();
		signInWithPopup(auth, provider)
			.then(async (result) => {
				const user: any = result.user;
				await connectSocmed({
					method: 'google',
					email: user.providerData[0].email,
					name: user.providerData[0].displayName,
					uid: user.providerData[0].uid,
					token: user.accessToken,
				}, dispatch)
			}).catch((error) => {
				dispatch({
					type: SET_ERRORS,
					payload: {
						error:true,
						message: 'Something Wrong. Please Try Again'
					}
				});
		}).finally(()=>{
			dispatch({
				type:UNLOADING_UI
			});
		});
	}

	const facebookSignIn = (type: 'login'|'register') => {
		dispatch({
			type: LOADING_UI
		});
		const provider = new FacebookAuthProvider();
		provider.addScope('email,public_profile');
		const auth = getAuth();
		signInWithPopup(auth, provider)
			.then(async (result) => {
				const user: any = result.user;
				if (user.providerData[0].email) {
					await connectSocmed({
						method: 'facebook',
						email: user.providerData[0].email,
						name: user.providerData[0].displayName,
						uid: user.providerData[0].uid,
						token: user.accessToken,
					}, dispatch)
				} else {
					setErrorLogin({
						error: true,
						msg: 'Something Wrong. Please Try Again'
					})
				}
			}).catch((error) => {
			dispatch({
				type: SET_ERRORS,
				payload: {
					error: true,
					message: 'Something Wrong. Please Try Again'
				}
			});
		}).finally(() => {
			dispatch({
				type: LOADING_UI
			});
		});
	}
	const appleSignIn = async (type: 'login'|'register') => {
		dispatch({
			type: LOADING_UI
		});
		const provider = new OAuthProvider('apple.com');
		provider.addScope('email');
		provider.addScope('name');
		const auth = getAuth();
		await signOut(auth);
		signInWithPopup(auth, provider)
			.then(async (result) => {
				if (auth?.currentUser?.displayName === null && !!auth.currentUser.providerData[0].displayName) {
					await updateProfile(auth.currentUser, {
						displayName: auth.currentUser.providerData[0].displayName,
					});
				}
				const user: any = result.user;
				// console.log(user,'log user auth current user apple');
				// console.log(auth.currentUser,'log user auth current user firebase');
				await connectSocmed({
					method: 'apple',
					email: user.providerData[0].email || '',
					name: auth?.currentUser?.providerData[0].displayName ? auth.currentUser.providerData[0].displayName : '',
					uid: user.providerData[0].uid,
					token: user.accessToken,
				}, dispatch)
			}).catch((error) => {
			dispatch({
				type: SET_ERRORS,
				payload: {
					error: true,
					message: 'Something Wrong. Please Try Again'
				}
			});
		}).finally(()=>{
			dispatch({
				type:UNLOADING_UI
			});
		});
	}

	const connectSocmed = async (data: LoginSocialType, dispatch: any) => {
		const resp = await loginSocial(data, dispatch)
		dispatch({
			type:UNLOADING_UI
		});
		if(!resp){
			if(queryParams.get('red')){
				history.push(`/sign-up?red=${queryParams.get('red')}`)
			}else{
				history.push('/sign-up')
			}
		}else{
			if(modalLogin.goPremium){
				const token = getToken();
				document.location.href = (process.env.REACT_APP_ACCOUNT_URL || '')+`/#/login?_t=${token}&type=subscription`;
			}else{
				window.location.replace(queryParams.get('red') || '/')
			}
		}
	}

	return (
		<Modal show={show} onHide={handleClose} className={'modal-login'}>
			<Modal.Header closeButton closeVariant={'white'}></Modal.Header>
			<Modal.Body>
				<div className={'ml-content'}>
					{!showLogin ? <ViewSignUp />: <ViewLogin />}
				</div>
			</Modal.Body>
		</Modal>
	)
}
export default ModalLogin
