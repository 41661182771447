import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import './HeaderArtist.scss'
interface IHeaderArtist {
	cover?: string
	name?: string
	bio?: string
}
const HeaderArtist = (props: IHeaderArtist) => {
	const [showBio, setShowBio] = useState(false)
	const handleCloseBio = () => setShowBio(false);
	const handleShowBio = () => setShowBio(true);
	return <>
		<div className={'header-content-artist'}>
			<div className={'hca-bg'}>
				{props?.cover && props?.cover !=="" && <img src={props?.cover} className={'single-bg'} />}
			</div>
			<div className={'d-flex align-items-end hca-container'}>
				<div className={'d-flex flex-column hca-content'}>
					<h2 className={'hca-name mb-1'}>{props?.name}</h2>
					{props.bio && <div className={'d-flex align-items-center'} onClick={handleShowBio}>
						<p className={'hca-bio me-3'}>{props?.bio}</p>
						<i className={'fa fa-chevron-right'}></i>
					</div>}
				</div>
			</div>
		</div>
		<Modal show={showBio} onHide={handleCloseBio} className={'modal-bio'}>
			<Modal.Header closeButton closeVariant={'white'}></Modal.Header>
			<Modal.Body>
				<div className={'mb-bio d-flex justify-content-center align-items-center'}>
					<div className={'mb-bio-content d-flex flex-column'}>
						<img src={props?.cover} />
						<h2 className={'mb-bio-name'}>{props?.name}</h2>
						<p className={'mb-bio-desc'}>{props?.bio}</p>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}

export default HeaderArtist
