import {
  LOADING_UI,
  UNLOADING_UI,
  SET_ERRORS,
  CLEAR_ERRORS, SHOW_MODAL_LOGIN, HIDE_MODAL_LOGIN, SHOW_MODAL_PREMIUM, HIDE_MODAL_PREMIUM, GO_PREMIUM, UNGO_PREMIUM,
} from "../types";
import { ActionType, InitialStateUIType } from '../../typings/state'

const initialState: InitialStateUIType = {
  loading: false,
  error: false,
  message: '',
  modalLogin: false,
  modalPremium: false,
  showSignUp: false,
  goPremium: false,
};

export default function (state = initialState, action: ActionType) {
  switch (action.type) {
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case UNLOADING_UI:
      return {
        ...state,
        loading: false,
      };
    case SET_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: false,
        message: '',
      };
    case SHOW_MODAL_LOGIN:
      return {
        ...state,
        ...action.payload,
        modalLogin: true,
      };
    case HIDE_MODAL_LOGIN:
      return {
        ...state,
        modalLogin: false,
      };
    case SHOW_MODAL_PREMIUM:
      return {
        ...state,
        ...action.payload,
        modalPremium: true,
      };
    case HIDE_MODAL_PREMIUM:
      return {
        ...state,
        ...action.payload,
        modalPremium: false,
      };
    case GO_PREMIUM:
      return {
        ...state,
        ...action.payload,
        goPremium: true,
      };
    case UNGO_PREMIUM:
      return {
        ...state,
        ...action.payload,
        goPremium: false,
      };
    default:
      return state;
  }
}
