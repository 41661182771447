import * as React from 'react'

export interface IButton {
  className?: string
  type?: 'submit'|'button'|null
  onClick?: Function
  primary?: boolean
  secondary?: boolean
  white?: boolean
  block?: boolean
  disabled?: boolean
}
const Button: React.FC<React.PropsWithChildren<IButton>> = (props) => {
  let style = `btn`;
  if(props.primary){
    style+= ' btn-primary'
  }else if(props.secondary){
    style+= ' btn-secondary'
  }else if(props.white){
    style= 'btn-white'
  }
  if(props.block){
    style+= ' w-100';
  }
  let classButton = `${style} ${props.className?props.className:''}`;
  let button = <button className={classButton} type={props?.type ?? 'submit'} disabled={props.disabled}>{props.children}</button>
  if(props.onClick){
    const onClick = props.onClick;
    button = <button
      type={props?.type ?? 'submit'}
      className={classButton}
      disabled={props.disabled}
      onClick={(event)=>{
        onClick(event);
        }
      }
    >
      {props.children}
    </button>
  }
  return button;
}
export default Button;
