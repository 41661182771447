import React, {useEffect, useState} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './styles/styles.scss';
import './styles/mobile-view.scss';
import Routes from './router/routes'
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui'
import { connect } from 'react-redux'
import { getToken, getUser, isAuthenticated } from './utils/common'
import { UseAppDispatch } from './redux/store'
import { SET_USER } from './redux/types'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MusicPlayerWrapper from "./components/layout/MusicPlayerWrapper";
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import imgMobile from './assets/images/MVMobile.png'
import logoSmall from './assets/images/logo/mv-logo-small-blue.png'
interface AppProps{
  UI?: any;
}

const queryClient = new QueryClient({});
const App:(props: AppProps) => JSX.Element = (props) => {
  const dispatch = UseAppDispatch();
  const [blocking, setBlocking] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(()=>{
    setBlocking(props.UI.loading);
  },[props.UI.loading])
  useEffect(()=>{
    if(isAuthenticated()){
      const user = getUser();
      const token = getToken();
      dispatch({
        type:SET_USER,
        payload:{
          profile:user,
          token,
        }
      })
    }
    setLoaded(true)
  },[dispatch])

  const DisplayMobileView = () => {
    return <>
      <div className={'main-mobile'}>
        <div className={'mobile-footer'}>
          <div className={'mf-content'}>
            <img className={'logo-small'} src={logoSmall} alt={'logo'}/>
            <span className={'mf-desc-big'}>
              Listen to Sacred Music from Adventist Musicians from All Over the World on Your Mobile Phone
            </span>
            <span className={'mf-desc-small'}>
              Download MusicVerse Today and Discover Hymns, Scripture Songs and other Godly Music
            </span>
            <a href={'https://musicverse.onelink.me/vYmO/WebPlayer'} className={'btn-download-mobile'}>
              Download Now!
            </a>
          </div>
        </div>
        <div className={'mobile-content'}></div>
        <div className={'mobile-image'}>
          <img className={'img-apps'} alt={'apps'} src={imgMobile}/>
        </div>
      </div>
    </>
  }

  return (
    <QueryClientProvider client={queryClient}>
      { !isMobile ? (
        <BlockUi tag="div" blocking={blocking} message="Loading, please wait">
          {loaded && <Routes />}
          <MusicPlayerWrapper />
          <ToastContainer />
        </BlockUi>
      ) : (
        <DisplayMobileView />
      )}
    </QueryClientProvider>
  );
}
const mapStateToProps = (state: any) => {
  return {
    UI: state.UI,
  };
};
export default connect(mapStateToProps,null)(App);
