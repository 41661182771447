import './Header.scss'
import logoSmall from '../../assets/images/logo/logo-white.png'
import DropDownMenuHeader from "./DropDownMenuHeader";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {InitialStateUserType} from "../../typings/state";
import {useEffect, useState} from "react";
import {getToken} from "../../utils/common";
import {GO_PREMIUM, SHOW_MODAL_PREMIUM} from "../../redux/types";
import {UseAppDispatch} from "../../redux/store";

const Header = () => {
	const dispatch = UseAppDispatch();
	const history = useHistory()
	const user = useSelector((state: {user: InitialStateUserType}) => state.user)
	const [showPremium, setShowPremium] = useState(false)
	const goPremium = () => {
		if(user.authenticated){
			const token = getToken();
			document.location.href = (process.env.REACT_APP_ACCOUNT_URL || '')+`/#/login?_t=${token}&type=subscription`;
		}else{
			dispatch({
				type: GO_PREMIUM,
			})
			dispatch({
				type: SHOW_MODAL_PREMIUM,
				payload: {
					thumbnail: null
				}
			})
		}
	}
	useEffect(() => {
		if(user.authenticated){
			setShowPremium(user.profile?.type === "Free")
		}else{
			setShowPremium(true)
		}
	},[user.authenticated])
	return (
		<div className={'header'}>
			<div className={'header-left'}>
				<div className={'header-nav'} onClick={()=>history.goBack()}>
					<i className={'fa fa-chevron-left'} ></i>
				</div>
				<div className={'header-nav'} onClick={()=>history.goForward()}>
					<i className={'fa fa-chevron-right'} ></i>
				</div>
			</div>
			<div className={'header-right'}>
				{showPremium && <button className={'premium'} onClick={() => goPremium()}>
					<img src={logoSmall}/>
					<span>Go Premium</span>
				</button> }
				{user.authenticated && <DropDownMenuHeader />}
			</div>
		</div>
	)
}
export default Header
