import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import {useInfiniteQuery, useQuery} from "react-query";
import {SearchItem} from "../../typings/search";
import {request} from "../../services/api.service";
import {ApiDataResponse} from "../../typings/api";
import Button from "../layout/UI/Button";
import "./ModalAddPlaylist.scss";
import {toast} from "react-toastify";

interface IModalAddPlaylist {
	show?: boolean
	music_slug: string
	onHide: Function

}
const ModalAddPlaylist = (props: IModalAddPlaylist) => {
	const [show, setShow] = useState(false)
	const [querySearch, setQuerySearch] = useState<string>('')
	const [selectedPlaylist, setSelectedPlaylist] = useState<string | null>(null)
	const { data: searchData, fetchNextPage, refetch } = useInfiniteQuery(['search-add-to-playlist'], async ({ pageParam = 1 }) => {
		try{
			const response = await request('GET',`/webplayer/v1/lib/main`,{
				type: 4,
				q: querySearch,
				page: pageParam,
			})
			console.log(response?.data);
			return response as ApiDataResponse
		}catch (e) {
			return []
		}
	},{
		getPreviousPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		getNextPageParam: (firstPage: any) => firstPage?.meta?.more === 'Y' ? firstPage.meta.next_page : undefined,
		enabled: false
	})
	useEffect(() => {
		if(props.music_slug !== '' && !show){
			setShow(true)
		}else if(show){
			console.log('load data');
			refetch()
		}
	},[show,props.music_slug])
	useEffect(()=>{
		console.log('search data', searchData)
	},[searchData])
	const handleClose = () => props.onHide();
	const onClickPlaylist = (playlistSlug: string,index: number) => {
		const docs = document.querySelectorAll('.list-playlist-to-add')
		docs.forEach(d => {
			d.classList.remove('active-state');
		})
		const elm = document.getElementById(`add-pl-${playlistSlug}`)
		if(elm){
			elm.classList.add('active-state')
		}
		setSelectedPlaylist(playlistSlug)
	}
	const handleQuerySearch = (value: string) => {
		setQuerySearch(value);
	}
	const enterSearch = () => {
		refetch()
	}
	const submitAddToPlaylist = async () => {
		const res = await request('POST',`/webplayer/v1/lib/playlist/add-music`,{
			"music_slug": props.music_slug,
			"playlist_slug": selectedPlaylist
		})
		toast.success("Added to your playlist!", {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
		});
		handleClose()
	}
	return <>
		<Modal show={show} onHide={handleClose} className={'modal-add-playlist'}>
			<Modal.Header closeButton closeVariant={'white'}></Modal.Header>
			<Modal.Body>
				<div className={'mb-add-playlist d-flex flex-column justify-content-center align-items-center'}>
					<input
						type='text'
						className={'search-playlist mb-3'}
						placeholder={'Search Playlist'}
						value={querySearch || ''}
						onChange={event => {
							handleQuerySearch(event.target.value)
						}}
						onKeyDown={event => {
							if (event.key === 'Enter' || event.keyCode === 13) {
								enterSearch()
							}
						}}
					/>
					<div className={'list-add-playlist list-group'} style={{
						height: '350px',
						overflowY: 'auto'
					}}>
						{ searchData?.pages.map((s: any, idx1) => (
							<React.Fragment key={`map-${idx1}`}>
								{ s.data.map((p: any, idx2: number) => (
									<button
										className={`list-group-item list-playlist-to-add`}
										id={`add-pl-${p.slug}`}
						        key={`map2-${idx2}`}
										onClick={()=>onClickPlaylist(p.slug,idx2)}
									>
										{p.name}
									</button>
									))
								}
							</React.Fragment>
						))}
					</div>
					<Button primary disabled={selectedPlaylist === null} className={'mt-4 w-100'} onClick={submitAddToPlaylist}>Save</Button>
				</div>
			</Modal.Body>
		</Modal>
	</>
}
export default ModalAddPlaylist
