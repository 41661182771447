import {request} from "./api.service";

const getTypeSource = (source: string) => {
	switch (source) {
		case 'album':
			return 2
		case 'playlist' :
			return 3
		default:
			// single
			return 1;
	}
}

export const sendPlayTrack = async (music_slug: string, type: string, source_slug?: string) => {
	let data: any = {
		"slug" : music_slug,
		"source" : getTypeSource(type),
		"is_offline": true,
	}
	if(type === 'playlist' && source_slug){
		data['source_slug'] = source_slug
	}
	await request('POST',`/webplayer/v1/music/play`,data)
}
